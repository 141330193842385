import { setConversions } from '@/utils/paramsUtils';
import axios from 'axios';

const { get, post, patch } = axios;

// nodes
export const createNode = (pathwayId, data) => post(`/v1/pathways/${pathwayId}/nodes/`, data);
export const patchNode = (caseId, nodeId, data) => patch(`/v1/cases/${caseId}/nodes/${nodeId}/`, data);
export const getNode = (pathwayId, nodeId) => get(`/v1/pathways/${pathwayId}/nodes/${nodeId}/`);
export const removeNode = (pathwayId, nodeId) => axios.delete(`/v1/pathways/${pathwayId}/nodes/${nodeId}/`);
export const getNodeInfo = (caseId, nodeId) => get(`/v1/cases/${caseId}/nodes/${nodeId}/`);
export const getNodeWithInfo = async (pathwayId, caseId, nodeId) => {
  const [
    { data: node },
    {
      data: { params: paramUnits, name },
    },
  ] = await Promise.all([getNode(pathwayId, nodeId), getNodeInfo(caseId, nodeId)]);

  // merge units into params
  node.params.forEach(param => setConversions(param, paramUnits));
  node.label = name || node.label;

  return node;
};
