import PropTypes from 'prop-types';
import { useSubmit, useNavigate } from 'react-router-dom';
import ActionsDropdown from '../core/ActionsDropdown';

const MembersList = ({ members }) => {
  const submit = useSubmit();
  const navigate = useNavigate();

  const remove = member => {
    submit(member, {
      method: 'delete',
      action: `remove-member`,
    });
  };

  const edit = member => {
    navigate(`edit-member`, { state: { member } });
  };

  if (!members.length) {
    return <p className="p-5 text-center">No members found</p>;
  }

  return (
    <table className="w-full text-left text-sm mt-2">
      <tbody>
        {members.map(member => (
          <tr
            key={member.email}
            className="group flex justify-start items-center px-1 px-4 text-sm text-gray-700 rounded-md hover:bg-gray-100"
          >
            <td className="w-2/4 py-3 flex">
              <a rel="noreferrer" target="_blank" href={`mailto:${member.email}`}>
                {member.name ?? member.email}
              </a>
            </td>
            <td className="w-1/4 py-3 capitalize">{member.role}</td>
            <td className="w-1/4 group-hover:inline hidden">
              <ActionsDropdown resource={member} onDelete={remove} onRename={edit} renameLabel="Edit" />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

MembersList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
};

export default MembersList;
