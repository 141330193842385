import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { useRef } from 'react';
import './plugins';
import { CHART_COLORS } from '@/consts';
import { horizontalLegendBar } from './plugins';

const buildOptions = yAxisLabel => ({
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    yAxisLabel: {
      enabled: true,
      text: yAxisLabel,
    },
    tooltip: {
      callbacks: {
        label: context => {
          return `${context.raw.toFixed(2)}%`;
        },

        title: context => {
          return context[0].dataset.label;
        },
      },
    },
  },
  indexAxis: 'y',
  responsive: true,
  animation: true,
  aspectRatio: 2,
  layout: {
    padding: {
      top: 50,
      bottom: 140,
    },
  },
  borderSkipped: false,
  borderWidth: 1,
  scales: {
    x: {
      max: 100,
      display: false,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      display: false,
      stacked: true,
      beginAtZero: true,
      grid: {
        display: false,
        drawBorder: false,
        drawTicks: false,
      },
      ticks: {
        display: false,
      },
    },
  },
});

const buildData = (data, colors) => {
  const { rows, labels } = data;
  const datasets = rows.map((row, i) => ({
    label: labels[i],
    data: [row],
    backgroundColor: colors[i],
    borderColor: ['rgb(255, 255, 255)'],
  }));

  return {
    labels: [''],
    datasets,
  };
};

const HorizontalStackedBarChart = ({ rows, labels, yAxisLabel, colors = CHART_COLORS }) => {
  const options = buildOptions(yAxisLabel);
  const data = buildData({ rows, labels }, colors);
  const ref = useRef();

  return (
    <div className="relative w-full">
      <Bar ref={ref} options={options} data={data} plugins={[horizontalLegendBar]} />
    </div>
  );
};

HorizontalStackedBarChart.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  yAxisLabel: PropTypes.string,
};

export default HorizontalStackedBarChart;
