import PropTypes from 'prop-types';
import { getConversionOptions } from '@/utils/unitUtils';
import Select from '../core/form/Select';

const ConversionField = ({ name, conversions, className = 'join-item w-1/4 border-l-0' }) => {
  const options = getConversionOptions(conversions);

  if (options.length === 1) return <div className="input input-sm input-bordered">{options[0].label}</div>;

  return <Select name={name} options={options} className={`select-sm w-fit ${className}`} />;
};

ConversionField.propTypes = {
  name: PropTypes.string,
  conversions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default ConversionField;
