import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';

const initialValues = {
  accessToken: null,
  refreshToken: null,
  email: null,
};

const useUserStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'userStore',
      version: 1,
    }),
  ),
);

const { setState: set, getState: get } = useUserStore;

// actions
export const userActions = {
  login: async loginData => {
    try {
      const { data } = await userApi.login(loginData);

      if (data) {
        set({
          accessToken: data.access,
          refreshToken: data.refresh,
          email: loginData.email,
        });
      }
    } catch (error) {
      return {
        error: error?.response?.data?.detail,
      };
    }
  },
  refresh: async () => {
    const { refreshToken } = get();
    try {
      const { data } = await userApi.refresh(refreshToken);

      if (data) {
        set({ accessToken: data.access });
        return true;
      }

      return false;
    } catch {
      return false;
    }
  },
  logout: () => {
    userApi.logout();
    set(initialValues, true);
  },
};

// selectors
export const useAuthToken = () => useUserStore(store => store.accessToken);
export const useEmail = () => useUserStore(store => store.email);
export default useUserStore;
