import {
  createComparisonCase,
  deleteComparisonCase,
  getComparison,
  getComparisonResults,
  patchComparisonCase,
} from '@/api/comparisons';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { isEmpty, keyBy, resolveAllArrays } from '@/utils/miscUtils';
import { getPathway, getPathways } from '@/api/pathways';
import pDebounce from 'p-debounce';
import { nanoid } from 'nanoid';
import {
  getComparisonEmissionTypes,
  getSavedComparisonCases,
  getUnits,
  parseCOACComparisonData,
  parseCOACComparisonFormData,
  parseLCAComparisonData,
  parseLCAComparisonFormData,
  parseTEAComparisonData,
  parseTEAComparisonFormData,
} from '@/utils/comparisonUtils';
import axios from 'axios';
import { getCase } from '@/api/cases';
import { applyNodeInfo } from '@/utils/pathwayUtils';

const initialValues = {
  comparisonCases: {},
  lca: {},
  tea: {},
  coac: {},
  error: null,
  lcaVisible: true,
  teaVisible: true,
  coacVisible: true,
};

const chartParsers = {
  lca: parseLCAComparisonData,
  tea: parseTEAComparisonData,
  coac: parseCOACComparisonData,
};

const formDataParsers = {
  lca: parseLCAComparisonFormData,
  tea: parseTEAComparisonFormData,
  coac: parseCOACComparisonFormData,
};

const debouncePatchComparisonCase = pDebounce(patchComparisonCase, 300);
const debounceCreateComparisonCase = pDebounce(createComparisonCase, 300);

const useComparisonStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useComparisonStore;

const abortControllers = {};

// actions
export const comparisonActions = {
  init: async (projectId, comparisonId) => {
    comparisonActions.clear();
    let { pathwaysByCaseId } = get();
    const { data: comparison } = await getComparison(comparisonId);

    // fetch pathways if they are not present
    if (isEmpty(pathwaysByCaseId)) {
      const {
        data: { results: pathways },
      } = await getPathways(projectId, { analyzed: true });
      pathwaysByCaseId = keyBy(pathways, 'case_id');
    }

    const { id, name, comparison_cases: comparisonCases = [] } = comparison;
    const size = comparisonCases.length;
    const comparisonCasesById = keyBy(
      comparisonCases.map(c => ({ ...c, key: c.id })),
      'key',
    );

    // fetch full pathways in order to get nodes
    if (comparisonCases.length) {
      const fetchPathways = comparisonCases
        .filter(
          comparsionCase =>
            pathwaysByCaseId?.[comparsionCase.case_id] && !pathwaysByCaseId?.[comparsionCase.case_id]?.nodes,
        )
        .map(comparsionCase => [
          getPathway(pathwaysByCaseId?.[comparsionCase.case_id]?.id),
          getCase(comparsionCase.case_id),
        ]);
      const responses = await resolveAllArrays(fetchPathways);
      const fullPathways = responses.map(([result, { data: caseData }]) => {
        const { node_info } = caseData;
        const pathway = result.data;
        pathway.nodes = applyNodeInfo(pathway.nodes, node_info);
        return pathway;
      });

      fullPathways.forEach(({ case_id, nodes }) => {
        pathwaysByCaseId[case_id].nodes = keyBy(nodes, 'id');
      });
    }

    set({ id, name, comparisonCases: comparisonCasesById, pathways: pathwaysByCaseId });

    for (let i = size; i < 2; i++) {
      comparisonActions.addComparisonCase();
    }

    comparisonActions.refreshPlots();

    return comparison;
  },

  clearPathways: () => {
    set({ pathways: {} });
  },

  addComparisonCase: async () => {
    const { comparisonCases } = get();
    const size = Object.keys(comparisonCases).length;
    const key = nanoid();
    const comparisonCase = {
      id: null,
      key,
      name: `Compare ${size + 1}`,
      case_id: null,
      node_id: null,
      output_name: null,
    };

    set(state => {
      state.comparisonCases[key] = comparisonCase;
    });
  },

  updateComparisonCase: async comparisonCase => {
    const { id, name, case_id, node_id, output_name, key } = comparisonCase;
    const updatedCase = { name, case_id, node_id, output_name };

    const { pathways } = get();
    const pathway = pathways?.[case_id];

    if (!pathway?.nodes) {
      const { data: fullPathway } = await getPathway(pathway.id);
      const { data: caseData } = await getCase(case_id);
      const nodes = applyNodeInfo(fullPathway.nodes, caseData.node_info);

      set(state => {
        state.pathways[case_id].nodes = keyBy(nodes, 'id');
      });
    }

    if (name && case_id && node_id && output_name) {
      if (id) {
        await debouncePatchComparisonCase(id, updatedCase);
      } else {
        const { data } = await debounceCreateComparisonCase(get().id, updatedCase);
        updatedCase.id = data.id;
      }
    }

    set(state => {
      state.comparisonCases[key] = { id, key, ...updatedCase };
    });

    comparisonActions.refreshPlots();
  },

  cloneComparisonCase: async comparisonCase => {
    const { name, case_id, node_id, output_name } = comparisonCase;
    const clonedCase = { case_id, node_id, output_name, key: nanoid(), name: `${name} (Copy)` };

    comparisonActions.updateComparisonCase(clonedCase);
  },

  removeComparisonCase: async comparisonCase => {
    const { id, key } = comparisonCase;

    if (id) {
      await deleteComparisonCase(id);
    }

    set(state => {
      delete state.comparisonCases[key];
    });

    comparisonActions.refreshPlots();
  },

  updateNodes: async pathway => {
    let { nodes } = pathway;

    if (nodes) {
      return nodes;
    }

    const { data: fullPathway } = await getPathway(pathway.id);
    nodes = keyBy(fullPathway.nodes, 'id');

    set(state => {
      state.pathways[pathway.case_id].nodes = nodes;
    });

    return nodes;
  },

  // graphs

  refreshPlots: async () => {
    const { comparisonCases, pathways } = get();
    const savedCases = getSavedComparisonCases(comparisonCases);

    if (!savedCases?.length) {
      return comparisonActions.resetPlots();
    }

    const units = getUnits(comparisonCases, pathways);

    if (!units?.length) {
      return comparisonActions.resetPlots();
    }

    const levelizationUnit = JSON.stringify(units[0]);
    const emissionTypes = getComparisonEmissionTypes(comparisonCases, pathways);
    comparisonActions.setError('');

    const options = {
      levelizationUnit,
      emissionTypes,
      savedCases,
    };

    try {
      await Promise.all([
        comparisonActions.addPlot('lca', options),
        comparisonActions.addPlot('tea', options),
        comparisonActions.addPlot('coac', options),
      ]);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log('err', err);
        comparisonActions.setError(err?.response?.data?.non_field_errors?.[0]);
      }
    }
  },

  addPlot: async (plotType, options) => {
    const { levelizationUnit, emissionTypes, savedCases } = options;
    const formData = {
      emissionType: emissionTypes[0].value,
      levelizationUnit,
    };

    const params = formDataParsers[plotType](formData, options);
    const response = await comparisonActions.getResults(plotType, params, savedCases);

    if (!response) {
      return;
    }

    const { results, data } = response;
    const plotData = {
      data,
      results,
      ...formData,
    };

    set(state => {
      state[plotType] = plotData;
    });
  },

  updatePlot: async (plotType, plotFormData) => {
    const { comparisonCases } = get();
    const params = formDataParsers[plotType](plotFormData);
    const savedCases = getSavedComparisonCases(comparisonCases);

    const response = await comparisonActions.getResults(plotType, params, savedCases);

    if (!response) {
      return;
    }

    const { results, data } = response;

    const plotData = {
      ...plotFormData,
      data,
      results,
    };

    set(state => {
      state[plotType] = plotData;
    });
  },

  getResults: async (plotType, params, savedCases) => {
    const { id } = get();
    const resultParser = chartParsers[plotType];
    const comparisonCasesById = keyBy(savedCases, 'id');

    if (abortControllers[plotType]) {
      abortControllers[plotType].abort();
    }

    const controller = new AbortController();

    abortControllers[plotType] = controller;

    const { data } = await getComparisonResults(id, plotType, params, {
      signal: controller.signal,
    });

    if (isEmpty(data)) {
      return { results: null, data: null };
    }

    const columns = Object.keys(data).map(id => comparisonCasesById?.[id]?.name);

    return {
      results: resultParser(data, columns, params, savedCases),
      data,
    };
  },

  clear: () => set(initialValues),

  unmount: () => {
    Object.values(abortControllers).forEach(controller => {
      controller.abort();
    });
  },
  resetPlots: () => set({ lca: {}, tea: {} }),
  setError: error => set({ error }),
  togglePlot: plotType => {
    set(state => {
      state[`${plotType}Visible`] = !state[`${plotType}Visible`];
    });
  },
};

// selectors
export const useComparisonCases = () => useComparisonStore(store => Object.values(store.comparisonCases));
export const usePathwaysOptions = () =>
  useComparisonStore(store =>
    Object.values(store.pathways).map(pathway => ({ value: pathway.case_id, label: pathway.name })),
  );
export const usePathwayByCaseId = caseId => useComparisonStore(store => store.pathways?.[caseId]);
export const useNode = (caseId, nodeId) => useComparisonStore(store => store.pathways?.[caseId]?.nodes?.[nodeId]);
export const useComparisonError = () => useComparisonStore(store => store.error);
export const useLCAComparisonPlot = () => useComparisonStore(store => store?.lca);
export const useTEAComparisonPlot = () => useComparisonStore(store => store?.tea);
export const useCOACComparisonPlot = () => useComparisonStore(store => store?.coac);
export const useIsTEAVisible = () => useComparisonStore(store => store?.teaVisible);
export const useIsLCAVisible = () => useComparisonStore(store => store?.lcaVisible);
export const useIsCOACVisible = () => useComparisonStore(store => store?.coacVisible);
export const useSavedComparisonCases = () =>
  useComparisonStore(store => getSavedComparisonCases(store.comparisonCases));

export const useComparison = () => useComparisonStore(store => ({ name: store.name, id: store.id }));

export const useUnits = () =>
  useComparisonStore(store => {
    const { comparisonCases, pathways } = store;
    return getUnits(comparisonCases, pathways);
  });

export const useEmissionTypes = () =>
  useComparisonStore(store => {
    const { comparisonCases, pathways } = store;
    return getComparisonEmissionTypes(comparisonCases, pathways);
  });

export default useComparisonStore;
