import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { getIOOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';

const levelizeOptions = [
  { value: false, label: 'Total' },
  { value: true, label: 'Per Functional Unit' },
];

const distributionOptions = [
  {
    label: 'LCA',
    value: 'emissions',
  },
  {
    label: 'TEA',
    value: 'levelized_cost',
  },
];

const MCPlotForm = ({ defaultValues, onSubmit }) => {
  const { levelized_cost } = defaultValues;
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, control, setValue } = methods;
  const nodes = useNodes();
  const nodeOptions = nodes.map(({ id, label }) => ({ label, value: id }));

  const [nodeId, levelize, distribution] = useWatch({
    control,
    name: ['nodeId', 'levelize', 'distribution'],
    defaultValue: defaultValues,
  });

  const node = useNodeById(nodeId);

  const levelizeByOptions = getIOOptions(node);
  const defaultLevelizeBy = levelizeByOptions?.[0]?.value;

  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  useEffect(() => {
    setValue('levelizeBy', defaultLevelizeBy);
  }, [defaultLevelizeBy, setValue]);

  useEffect(() => {
    const subscription = watch(debouncedSave);
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave]);

  return (
    <div className="group node">
      <FormProvider {...methods}>
        <form>
          <div className="grid grid-cols-2 gap-1">
            <div>
              <FormRow className="overflow-hidden" label="Module" name="nodeId">
                <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
              </FormRow>
            </div>
            <div>
              <FormRow className="overflow-hidden" label="Analysis" name="distribution">
                <Select className="w-full truncate" name="distribution" options={distributionOptions} />
              </FormRow>
            </div>
            {distribution === 'emissions' && (
              <>
                <div>
                  <FormRow className="overflow-hidden mb-2" label="Values" name="levelize">
                    <Select className="w-full truncate" name="levelize" options={levelizeOptions} />
                  </FormRow>
                </div>
                <div>
                  <FormRow
                    className={`overflow-hidden mb-2 ${levelize !== 'true' ? 'hidden' : ''}`}
                    label="Functional Unit"
                    name="levelizeBy"
                  >
                    <div className="flex items-center">
                      <Select className="w-full truncate" name="levelizeBy" options={levelizeByOptions} />
                    </div>
                  </FormRow>
                </div>
              </>
            )}

            {distribution === 'levelized_cost' && levelized_cost.unit.match(/electricity/i) && (
              <>
                <div>
                  <FormRow className="mb-2" label="Unit" name="scalar">
                    <Select
                      name="scalar"
                      options={[
                        { value: 1, label: 'kWh' },
                        { value: 1000, label: 'MWh' },
                      ]}
                      registerOptions={{ valueAsNumber: true }}
                    />
                  </FormRow>
                </div>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

MCPlotForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default MCPlotForm;
