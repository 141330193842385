import { useSystemParams, useSystemValues, systemActions, useSystemId, useSystemType } from '@/stores/systemStore';
import ParameterForm from '@/components/parameters/ParameterForm';
import { prepareFormData } from '@/utils/paramsUtils';
import RunSystemButton from './RunSystemButton';
import OpenSystemAnalysisButton from './OpenSystemAnalysisButton';
import ToggleViewButton from './ToggleViewButton';
import { useState } from 'react';
import { VIEW_TYPES } from '@/consts';

const SystemInputs = () => {
  const systemType = useSystemType();
  const isHydrogenProduction = systemType === 'hydrogen_production';
  const [viewType, setViewType] = useState(isHydrogenProduction ? VIEW_TYPES.tabs : VIEW_TYPES.grid);
  const systemParams = useSystemParams();
  const systemValues = useSystemValues();
  const id = useSystemId();

  const { params, categories, defaultValues } = prepareFormData(systemParams, systemValues);
  const save = data => systemActions.patchParams(data);

  return (
    <div className="h-full overflow-y-auto scrollbar-thin">
      <ParameterForm
        key={id}
        className="pl-4 pr-4"
        params={params}
        categories={categories}
        title=""
        defaultValues={defaultValues}
        onSubmit={save}
        viewType={viewType}
        actions={
          <>
            <RunSystemButton />
            <OpenSystemAnalysisButton />
            <ToggleViewButton onToggle={setViewType} viewType={viewType} />
          </>
        }
      />
    </div>
  );
};

export default SystemInputs;
