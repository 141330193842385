import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import usePrevious from '@/hooks/usePrevious';
import { isDefined } from '@/utils/miscUtils';
import { convert } from '@/utils/unitUtils';
import { buildDynamicValidators } from '@/utils/validators';

const useConversion = (param, name, conversionFieldName) => {
  const { conversion, initValidators, options, baseConversion } = param;
  const { control, setValue, getValues } = useFormContext();
  const conversionStr = useWatch({ control, name: conversionFieldName ?? `conversion.${name}`, disabled: !conversion });
  const prevConversionStr = usePrevious(conversionStr);

  const currentConversion = conversionStr ? JSON.parse(conversionStr) : conversion;
  const [convertedOptions, setOptions] = useState(options);

  // convert parameter value based on the given conversion
  useEffect(() => {
    if (isDefined(conversionStr) && isDefined(prevConversionStr) && conversionStr !== prevConversionStr) {
      const currentValue = getValues(name);
      const from = JSON.parse(prevConversionStr);
      const to = JSON.parse(conversionStr);
      const value = parseFloat(currentValue);
      const convertedValue = convert(from, to, value);

      if (options) {
        const to = JSON.parse(conversionStr);
        const updatedOptions = options.map(({ value, conditionals }) => {
          const convertedValue = convert({ factor: 1 }, to, value);
          return { value: convertedValue, conditionals };
        });

        setOptions(updatedOptions);

        setTimeout(() => {
          setValue(name, convertedValue);
        });
      } else {
        setValue(name, convertedValue);
      }
    }
  }, [options, conversionStr, prevConversionStr, name, getValues, setValue]);

  const validators = currentConversion ? buildDynamicValidators(initValidators, baseConversion, currentConversion) : {};

  return { validators, convertedOptions };
};

export default useConversion;
