import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import useToggle from '@/hooks/useToggle';

const Accordion = ({ title, children, open = false }) => {
  const { isOpen, toggle } = useToggle(open);

  return (
    <div className={`${isOpen ? 'border-b' : ''}`}>
      <div className="p-1 border-b flex items-center cursor-pointer bg-canvas" onClick={toggle}>
        <div className="btn btn-sm btn-ghost btn-circle">
          {isOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
        </div>
        <div className="pl-2 pr-2">
          <p className="font-semibold text-sm">{title}</p>
        </div>
      </div>
      <div className={`${isOpen ? 'visible' : 'hidden'}`}>{children}</div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
};

export default Accordion;
