import { ANALYSIS_STATUS } from '@/consts';
import { useAnalysisStatus, useIsAnalysisStale } from '@/stores/caseStore';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';

const OpenAnalysisButton = () => {
  const status = useAnalysisStatus();
  const isAnalysisStale = useIsAnalysisStale();
  const { projectId, pathwayId } = useParams();

  if (status === ANALYSIS_STATUS.succeeded && !isAnalysisStale) {
    return (
      <Link
        to={`/projects/${projectId}/analysis/pathways/${pathwayId}`}
        state={{ pathwayUrl: `/projects/${projectId}/build/pathways/${pathwayId}` }}
        className="btn btn-sm btn-primary"
      >
        <ChartBarIcon className="w-5 h-5" />
        View Analysis
      </Link>
    );
  }
};

export default OpenAnalysisButton;
