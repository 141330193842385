import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import {
  analyzeCase,
  balanceCase,
  createCostOverride,
  deleteCostOverride,
  patchAnchor,
  patchCostOverride,
  patchParams,
} from '@/api/cases';
import { serializeHandle } from '@/utils/pathwayUtils';
import { retrieveAnalysis } from '@/api/analyses';
import { ANALYSIS_STATUS, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER } from '@/consts';
import { keyBy, removeEmpty, runInterval } from '@/utils/miscUtils';
import { prepareConversionParams, prepareMcParams } from '@/utils/paramsUtils';
import { unitToString } from '@/utils/unitUtils';
import Decimal from 'decimal.js';
import { resourcesActions } from './resourcesStore';

const initialValues = {
  anchor: null,
  tempAnchor: null,
  params: null,
  mc_params: null,
  balancedNodes: null,
  balance_results: null,
  balanced: false,
  analyzed: false,
  analysis_status: null,
  analysis_id: null,
  flowRate: 1,
  cost_overrides: [],
  node_info: {},
};

const inputOutputMap = {
  input: 'inputs',
  output: 'outputs',
};

const useCaseStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useCaseStore;

// actions
export const caseActions = {
  init: caseData => {
    const balancedNodes = keyBy(caseData?.balance_results ?? [], 'id');

    set({ ...caseData, balancedNodes, flowRate: 1 }, true);
    const { analysis_status } = get();

    if (analysis_status === ANALYSIS_STATUS.pending || analysis_status === ANALYSIS_STATUS.running) {
      caseActions.checkAnalysisStatusPeriodically();
    }
  },

  checkAnalysisStatus: async () => {
    const { analysis_id: analysisId, pathway_id: pathwayId } = get();

    if (!analysisId) {
      return false;
    }

    try {
      const {
        data: { status },
      } = await retrieveAnalysis(analysisId);

      if (status === ANALYSIS_STATUS.succeeded || status === ANALYSIS_STATUS.failed) {
        set({ analysis_status: status });
        resourcesActions.updatePathway({ id: pathwayId, analysis_status: status });
        return true;
      }
    } catch (err) {
      set({ analysis_status: ANALYSIS_STATUS.failed });
      resourcesActions.updatePathway({ id: pathwayId, analysis_status: ANALYSIS_STATUS.failed });
      return true;
    }

    return false;
  },

  checkAnalysisStatusPeriodically: () => {
    runInterval(caseActions.checkAnalysisStatus, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER);
  },

  patchAnchor: async (handle, handleType, scalar, unit) => {
    const { id: caseId } = get();
    const [node_id, io_name] = serializeHandle(handle, handleType);
    const anchorData = {
      node_id,
      io_type: handleType,
      io_name,
      quantity: {
        scalar,
        unit,
      },
    };

    const { data } = await patchAnchor(caseId, anchorData);

    if (data.anchor) {
      const { anchor } = data;
      set({ anchor });
      caseActions.markUnbalanced();
    }
  },

  addCost: async (nodeId, handle, handleType, cost, unit) => {
    const { id: caseId } = get();
    const params = {
      node_id: nodeId,
      io_type: 'input',
      io_name: handle,
      cost,
      unit,
    };
    const { data } = await createCostOverride(caseId, params);

    set(state => {
      state.cost_overrides.push(data);
    });
    set({ analysis_stale: true });
  },

  updateCost: async (costOverride, cost, unit) => {
    const { id, node_id, io_type, io_name } = costOverride;
    const { data } = await patchCostOverride(id, { cost, unit, node_id, io_type, io_name });

    set(state => {
      state.cost_overrides = state.cost_overrides.map(costOverride => {
        if (costOverride.id === costOverride.id) {
          return data;
        }

        return costOverride;
      });
    });
    set({ analysis_stale: true });
  },

  deleteCost: async costId => {
    await deleteCostOverride(costId);

    set(state => {
      state.cost_overrides = state.cost_overrides.filter(cost => cost.id !== costId);
    });
    set({ analysis_stale: true });
  },

  patchParams: async (nodeId, paramsData) => {
    const { id: caseId } = get();
    const cleanedParams = removeEmpty(paramsData);
    const mcParams = prepareMcParams(paramsData);
    // eslint-disable-next-line no-unused-vars
    const { mc_params, conversion, ...params } = cleanedParams;
    const paramsWithConversions = prepareConversionParams(params, conversion);
    const { data } = await patchParams(caseId, { [nodeId]: paramsWithConversions }, mcParams && { [nodeId]: mcParams });

    set(state => {
      state.params[nodeId] = data.params[nodeId];
      state.mc_params[nodeId] = data.mc_params[nodeId];
    });

    caseActions.markUnbalanced();
  },

  balanceCase: async () => {
    const { id: caseId } = get();
    const { data } = await balanceCase(caseId);
    const balancedNodes = keyBy(data, 'id');

    set({ balance_results: data, balanced: true, balancedNodes });
  },
  analyzeCase: async params => {
    const { id: caseId, pathway_id: pathwayId } = get();
    const { data } = await analyzeCase(caseId, params);
    const { status, id, stale } = data;
    set({ analysis_status: status, analysis_id: id, analysis_stale: stale });
    resourcesActions.updatePathway({ id: pathwayId, analysis_status: status });

    caseActions.checkAnalysisStatusPeriodically();
  },

  setTempAnchor: tempAnchor => set({ tempAnchor }),
  clearTempAnchor: () => set({ tempAnchor: null }),
  markUnbalanced: () => set({ balanced: false, analyzed: false, analysis_status: null }),
  changeFlowRate: flowRate => set({ flowRate }),
  clear: () => {
    set(initialValues, true);
  },
};

// selectors
export const useAnchorId = () =>
  useCaseStore(store => {
    const { anchor } = store;

    if (anchor) {
      const { node_id, io_name, io_type } = anchor;
      return `${node_id}_${io_type}_${io_name}`;
    }

    return null;
  });

export const useAnchor = () => useCaseStore(store => store.anchor);
export const useCaseId = () => useCaseStore(store => store.id);
export const useTempAnchor = () => useCaseStore(store => store.tempAnchor);
export const useCostOverridesByNode = nodeId =>
  useCaseStore(store => store?.cost_overrides.find(co => co.node_id === nodeId));
export const useCostOverridesByNodeAndName = (nodeId, name) =>
  useCaseStore(store => store?.cost_overrides.find(co => co.node_id === nodeId && co.io_name === name));
export const useCaseParamsForNode = nodeId => useCaseStore(store => store?.params?.[nodeId]);
export const useCaseMcParamsForNode = nodeId =>
  useCaseStore(store => {
    const mcParams = store?.mc_params?.[nodeId] || {};

    return Object.keys(mcParams).reduce((acc, param) => {
      Object.keys(mcParams[param]).map(oParam => {
        acc[`mc_params.${param}.${oParam}`] = mcParams[param][oParam];
      });
      return acc;
    }, {});
  });

export const useIsBalanced = () => useCaseStore(store => store.balanced);
export const useBalancedNodes = () => useCaseStore(store => store.balancedNodes);
export const useNodeInfo = () => useCaseStore(store => store.node_info);
export const useBalancedPortValue = (nodeId, nodeType, nodeName) =>
  useCaseStore(store => {
    const { flowRate } = store;
    const balancedValues = store?.balancedNodes?.[nodeId]?.[inputOutputMap[nodeType]]?.[nodeName];

    if (!balancedValues) {
      return null;
    }

    const { quantities, species } = balancedValues;
    const parsed = quantities.map(({ scalar, unit }) => {
      let value = new Decimal(scalar);

      if (species !== 'electricity') {
        value = value.dividedBy(flowRate);
      }

      const unitStr = unitToString(unit);
      const rounding = value.toNumber() >= 1 ? 2 : 5;

      return `${value.toNumber().toFixed(rounding)} ${unitStr}`;
    });

    return parsed;
  });
export const useAnalysisStatus = () => useCaseStore(store => store.analysis_status);
export const useIsAnalysisStale = () => useCaseStore(store => store.analysis_stale);
export const useFlowRate = () => useCaseStore(store => store.flowRate);
export default useCaseStore;
