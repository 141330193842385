import PropTypes from 'prop-types';

import { Line } from 'react-chartjs-2';
import { useRef } from 'react';
import ExportDropdown from './ExportDropdown';

const colors = [
  '#a6cee3',
  '#b2df8a',
  '#fb9a99',
  '#fdbf6f',
  '#1f78b4',
  '#33a02c',
  '#e31a1c',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#ffff99',
  '#b15928',
];

const buildOptions = (text, yAxisLabel) => ({
  plugins: {
    title: {
      display: true,
      text,
    },
    legend: {
      align: 'center',
      title: {
        display: false,
      },
    },
    yAxisLabel: {
      enabled: !!yAxisLabel,
      text: yAxisLabel,
    },
  },
  responsive: true,
  animation: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  scaleShowValues: true,
  layout: {
    padding: {
      right: 10,
    },
  },
  scales: {
    x: {
      title: {
        display: false,
        text: 'Hours of Week',
      },
      ticks: {
        autoSkip: false,
        maxTicksLimit: 24,
        callback: (val, index) => {
          if (index == 0) return 1;

          return (index + 1) % 24 === 0 ? index + 1 : '';
        },
      },
    },
    y: {
      stacked: true,
      suggestedMin: 0,
      title: {
        display: false,
        text: 'Value',
      },
    },
  },
});

const buildData = data => {
  const { columns, rows, fill, unit } = data;
  const labels = [];

  const datasets = columns.map((label, i) => ({
    label,
    data: [],
    fill,
    pointRadius: 0,
    borderWidth: fill ? 1 : 2,
    tension: 0.1,
    backgroundColor: fill ? colors[i] : 'transparent',
    borderColor: fill ? '#000000' : colors[i],
  }));

  rows.forEach((columns, i) => {
    columns.forEach((column, index) => {
      datasets[index].data.push(column.value);
      datasets[index].tooltip = {
        callbacks: {
          title: () => '',
          label: context => {
            const label = [`${context.dataset.label}: ${context.parsed.y.toFixed(2)} ${unit}`];

            if (column.tooltip) {
              label.push(column.tooltip);
            }

            return label;
          },
        },
      };
    });
    labels.push(i + 1);
  });

  return {
    labels,
    datasets,
  };
};

const TimeSeriesChart = ({ rows, columns, label, yAxisLabel, fill, unit }) => {
  const options = buildOptions(label, yAxisLabel);
  const data = buildData({ rows, columns, fill, unit });
  const ref = useRef();

  return (
    <div className="min-h-96 relative w-full">
      <ExportDropdown forwardedRef={ref} data={data} />
      <Line ref={ref} options={options} data={data} />
    </div>
  );
};

TimeSeriesChart.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array),
  columns: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  yAxisLabel: PropTypes.string,
  fill: PropTypes.bool,
  unit: PropTypes.string,
};

export default TimeSeriesChart;
