import { Outlet } from 'react-router-dom';
import SystemInputs from '@/components/systems/SystemInputs';

const SystemRoute = () => (
  <div className="bg-canvas h-full px-4 pb-4">
    <SystemInputs />
    <Outlet />
  </div>
);

export default SystemRoute;
