import { useCollapsed } from '@/stores/menuStore';
import PropTypes from 'prop-types';

const ListPane = ({ children }) => {
  const collapsed = useCollapsed();
  const listClasses = collapsed ? 'invisible' : 'visible';
  return <nav className={`transition-[opacity] duration-200 ${listClasses}`}>{children}</nav>;
};

ListPane.propTypes = {
  children: PropTypes.node,
};

export default ListPane;
