import { Navigate, Outlet } from 'react-router-dom';
import { useAuthToken, useEmail } from '@/stores/userStore';
import ToastContainer from '@/components/core/ToastContainer';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const PrivateRoute = () => {
  const authorized = useAuthToken();
  const email = useEmail();

  useEffect(() => {
    if (authorized && email) {
      Sentry.setUser({ email });
    }
  }, [authorized, email]);

  if (!authorized) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Outlet />
      <ToastContainer />
    </>
  );
};

export default PrivateRoute;
