import PropTypes from 'prop-types';
import useCheckConditionals from '../parameters/useCheckConditionals';
import InputField from '../parameters/fields/InputField';
import SelectField from '../parameters/fields/SelectField';
import ToggleField from '../parameters/fields/ToggleField';
import { useState } from 'react';
import SwitchField from '../parameters/fields/SwitchField';
import GroupField from '../parameters/fields/GroupField';
import MarkdownViewer from '../core/MarkdownViewer';
import LocationField from '../parameters/fields/LocationField';

const paramTypes = {
  continuous: InputField, // deprecated
  numeric: InputField,
  string: InputField,
  options: SelectField,
  boolean: ToggleField,
  group: GroupField,
  location: LocationField,
  // TODO: register other types
};

const NodeFields = ({ params, description, categories, className = '' }) => {
  const conditionalChecks = useCheckConditionals(params);
  const initCategory = Object.values(categories)[0].categoryName;
  const [activeCategory, setActiveCategory] = useState(initCategory);

  const renderParams = params =>
    params
      .filter(param => paramTypes[param.type])
      .map(param => {
        const { name, type } = param;
        const FieldComponent = paramTypes[type];
        const { fieldCondMet, watchedFields, defaultValue } = conditionalChecks[name];
        const classes = `mx-auto pb-0 mt-6 mb-2 ${className}`;

        return (
          fieldCondMet && (
            <div key={name} className={classes}>
              <FieldComponent name={name} param={param} defaultValue={defaultValue} watchedFields={watchedFields} />
            </div>
          )
        );
      });

  const renderParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params;

    if (!paramsByCategory) {
      return null;
    }

    return renderParams(paramsByCategory);
  };

  return (
    <div className="max-h-[calc(100%_-_60px)] flex flex-col h-full menu-transition-2">
      <ul className="flex border-b border-gray-100 mx-8">
        {Object.values(categories).map(category => {
          const { categoryName, params } = category;
          // show the category if any param in the category passes it's conditional check
          const showCategory = params.some(({ name: paramName }) => conditionalChecks[paramName].fieldCondMet);

          return (
            (showCategory || categoryName === 'Info') && (
              <li key={categoryName} className="inline-block relative">
                <button
                  type="button"
                  onClick={() => {
                    setActiveCategory(categoryName);
                  }}
                  className={`transition ease-in-out delay-100 text-xs text-gray-500 flex items-center px-4 pt-2 pb-3 font-semibold rounded hover:bg-gray-50 hover:text-gray-800 duration-150 ${activeCategory === categoryName ? 'after:content-[""] after:h-[3px] after:bg-[#639EFF] after:w-full after:absolute after:bottom-0 after:left-0 text-gray-800' : 'text-gray-500'}`}
                >
                  {categoryName || 'Simple'}
                </button>
              </li>
            )
          );
        })}
      </ul>
      <div className="overflow-y-auto px-8 flex-grow-[2]">
        {Object.values(categories).map(({ categoryName }) => (
          <div className={`${activeCategory === categoryName ? 'visible' : 'hidden'}`} key={categoryName}>
            {categoryName === 'Info' ? (
              <MarkdownViewer
                content={description}
                className="prose mx-auto pb-0 mt-6 mb-2 prose-sm prose-h2:text-sm prose-h3:text-sm prose-p:text-xs"
              />
            ) : (
              renderParamsByCategory(categoryName)
            )}
          </div>
        ))}
      </div>
      <SwitchField param={{ label: 'Uncertainty Settings' }} name={`mc_params._enabled`} />
    </div>
  );
};

NodeFields.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  categories: PropTypes.object,
  className: PropTypes.string,
};

export default NodeFields;
