import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { userActions } from '@/stores/userStore';
import { useRouteError, useNavigate } from 'react-router-dom';

const useHandleAuthError = () => {
  const error = useRouteError();
  const navigate = useNavigate();
  const status = error?.response?.status;

  if (status !== 401) {
    Sentry.captureException(error);
  }

  useEffect(() => {
    if (status === 401) {
      userActions.logout();
      navigate('/login');
    }
  }, [status, navigate]);
};

export default useHandleAuthError;
