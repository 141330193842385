import PropTypes from 'prop-types';
import Header from '@/components/core/Header';

const Layout = ({ children }) => (
  <>
    <Header />
    <div className="container mx-auto">{children}</div>
  </>
);

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
