import ProjectList from '@/components/projects/ProjectList';
import Layout from '@/components/core/Layout';
import { Link, Outlet } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';

const ProjectsRoute = () => (
  <Layout>
    <div className="flex justify-end mt-8 mb-4">
      <Link to="/projects/new" className="btn btn-primary btn-sm">
        <PlusIcon className="h-5 w-5" />
        New Project
      </Link>
    </div>
    <p className="mt-8 ml-4 mr-2 mb-4 font-semibold text-lg">Project List</p>
    <div className="m-2 border border-gray-200 rounded shadow-xs">
      <ProjectList />
      <Outlet />
    </div>
  </Layout>
);

export default ProjectsRoute;
