import PropTypes from 'prop-types';
import { useParams, useNavigate, useSubmit } from 'react-router-dom';
import ActionsDropdown from '../core/ActionsDropdown';
import { confirm } from '@/components/core/createConfirmation';

const SystemActionsDropdown = ({ system }) => {
  const { systemId } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const actionPath = systemId ? `systems/${system.id}` : `systems/${system.id}`;

  const edit = system => navigate(`${actionPath}/edit`, { state: { system } });
  const duplicate = system => navigate(`${actionPath}/duplicate`, { state: { system } });
  const remove = async () => {
    if (await confirm('Are you sure you want to remove this system?')) {
      submit(system, {
        method: 'delete',
        action: `${actionPath}/destroy`,
      });
    }
  };

  return <ActionsDropdown onDelete={remove} onDuplicate={duplicate} onRename={edit} resource={system} />;
};

SystemActionsDropdown.propTypes = {
  system: PropTypes.object,
};

export default SystemActionsDropdown;
