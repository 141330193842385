import { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import { FormProvider, useForm } from 'react-hook-form';
import { registryActions, useSelectedFilterTags, useSubmittedFilterTags } from '@/stores/dataRegistryStore';

const DataRegistrySearchFilter = ({ defaultValues, onSubmit, tagsByCategory }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const methods = useForm({ defaultValues, mode: 'onChange' });
  const [toggleOption, setToggleOption] = useState('OR');
  let selectedFilters = useSelectedFilterTags();
  let submittedFilterTags = useSubmittedFilterTags();
  const [isFilterOn, setIsFilterOn] = useState(submittedFilterTags && submittedFilterTags.length > 0);

  const handleFilterToggle = () => {
    setIsFilterOpen(prevState => !prevState);
  };

  const handleLogicToggle = option => {
    setToggleOption(option);
    registryActions.setFilterLogicToggle(option);
  };

  // const handleSubmitFilters = (selectedFilterTags) => {
  //   handleFilterToggle;
  //   setIsFilterOn(true);
  //   registryActions.setFilter(selectedFilterTags);
  // }

  // const handleSelectedFilters = (selectedFilterTags) => {
  //   registryActions.updateFilter(selectedFilterTags)
  // }

  const { handleSubmit } = methods;

  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  const handleCheckboxChange = option => {
    if (!selectedFilters.includes(option)) {
      registryActions.updateFilter(selectedFilters.concat(option));
    } else if (selectedFilters.includes(option)) {
      registryActions.updateFilter(selectedFilters.filter(tag => tag !== option));
    }
  };

  const areAllSelected = category => {
    let allAreSelected = true;
    tagsByCategory[category].forEach(option => {
      if (!selectedFilters.includes(option)) {
        allAreSelected = false;
      }
    });
    return allAreSelected;
  };

  const handleSelectAll = category => {
    if (!areAllSelected(category)) {
      let selectingAll = [];
      tagsByCategory[category].forEach(option => {
        if (!selectedFilters.includes(option)) {
          selectingAll.push(option);
        }
      });
      registryActions.updateFilter(selectedFilters.concat(selectingAll));
    } else if (areAllSelected(category)) {
      registryActions.updateFilter(selectedFilters.filter(tag => tag === !tag));
    }
  };

  const formatTag = tag => {
    let formattedTag = '';
    if (tag.includes('_')) {
      const parts = tag.split('_');
      formattedTag = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
    } else if (tag.includes('/')) {
      const parts = tag.split('/');
      formattedTag = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('/');
    } else {
      formattedTag = tag.charAt(0).toUpperCase() + tag.slice(1);
    }
    return formattedTag;
  };

  return (
    <>
      <button onClick={handleFilterToggle}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={isFilterOn ? '#639EFF' : 'black'}
          className="size-7 mb-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
          />
        </svg>
      </button>

      {isFilterOpen && (
        <FormProvider {...methods}>
          <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
            <div className="relative w-[40vw] h-[60vh] bg-white border rounded-md shadow-lg p-4">
              <button
                onClick={handleFilterToggle}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

              {Object.keys(tagsByCategory).length > 0 && (
                <div className="flex flex-col h-full space-y-8 mt-2 pb-2">
                  <div className="mt-4 flex justify-center text-xl font-semibold text-gray-700">Data Filter</div>
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={() => handleLogicToggle('AND')}
                      className={`px-2 py-1 text-sm rounded ${toggleOption === 'AND' ? 'bg-blue-500 border border-blue-600 text-white' : 'bg-gray-100 border border-gray-200'}`}
                    >
                      AND
                    </button>
                    <button
                      onClick={() => handleLogicToggle('OR')}
                      className={`px-2 py-1 text-sm rounded ${toggleOption === 'OR' ? 'bg-blue-500 border border-blue-600 text-white' : 'bg-gray-100 border border-gray-200'}`}
                    >
                      OR
                    </button>
                  </div>

                  <div className="mt-10 ml-5 mr-5 flex flex-col flex-grow pb-3 overflow-y-auto">
                    {Object.keys(tagsByCategory).map(category => (
                      <div key={category}>
                        <div className="inline-flex flex gap-4">
                          <label htmlFor={category} className="block text-md font-medium text-gray-700 mt-3 mb-3">
                            {category
                              .split('_')
                              .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                              .join(' ')}
                          </label>

                          <div className="flex items-center">
                            <button
                              className=" text-xs py-1 px-1 text-gray-800 bg-gray-200 border border-gray-300 rounded-sm cursor-pointer hover:bg-gray-300 "
                              onClick={() => handleSelectAll(category)}
                            >
                              {!areAllSelected(category) ? 'Select All' : 'Deselect All'}
                            </button>
                          </div>
                        </div>

                        <div className="ml-2 inline-flex flex flex-wrap gap-2">
                          {tagsByCategory[category].map((option, index) => (
                            <div key={index} className="flex items-center mb-2">
                              <input
                                type="checkbox"
                                checked={selectedFilters.includes(option)}
                                id={`${category}-${index}`}
                                //onClick={() => registryActions.updateFilter(selectedFilters.concat(option))}
                                onClick={() => handleCheckboxChange(option)}
                                value={option}
                                className="mr-2"
                              />
                              <label
                                htmlFor={`${category}-${index}`}
                                className="px-1 py-1 bg-green-500 text-white rounded text-sm mr-5 whitespace-nowrap"
                              >
                                {formatTag(option)}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className=" flex justify-end space-x-5 pb-2">
                    <button
                      onClick={() => {
                        setIsFilterOpen(false);
                        if (isFilterOn) setIsFilterOn(false);
                        registryActions.updateFilter([]);
                        registryActions.setFilter(null);
                      }}
                      className="text-md px-2 py-1 bg-gray-500 hover:bg-gray-600 text-white rounded-md"
                    >
                      Clear Filter
                    </button>
                    <button
                      onChange={debouncedSave}
                      onClick={() => {
                        setIsFilterOpen(false);
                        if (!isFilterOn && selectedFilters.length > 0) setIsFilterOn(true);
                        else if (selectedFilters.length === 0) setIsFilterOn(false);
                        if (selectedFilters.length > 0) registryActions.setFilter(selectedFilters);
                      }}
                      className="text-md px-2 py-1 bg-[#639EFF] hover:bg-[#4A8BD4] text-white rounded-md"
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </FormProvider>
      )}
    </>
  );
};

DataRegistrySearchFilter.propTypes = {
  tagsByCategory: PropTypes.object,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default DataRegistrySearchFilter;
