import { toast } from 'react-toastify';

const updateOnlineStatus = () => {
  if (navigator.onLine) {
    toast.success('You are back online');
  } else {
    toast.error('You are currently offline');
  }
};

window.addEventListener('offline', updateOnlineStatus);
window.addEventListener('online', updateOnlineStatus);
