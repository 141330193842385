import dataSources from '@/dataSources';
import DataSourcesDashboardRoute from './DataSourcesDashboardRoute';
import { getDataRegistry } from '../../api/dataRegistry';
import { registryActions } from '../../stores/dataRegistryStore';

const dataSourceRouter = {
  path: '/projects/:projectId/datasources',
  async lazy() {
    //********
    let DataSourcesRoute = await import('./DataSourcesRoute');
    return { Component: DataSourcesRoute.default };
  },
  children: [
    {
      path: '/projects/:projectId/datasources/data_dashboard',
      // handle: {
      //   crumb: data => {
      //     return ['Data', 'Dashboard'];
      //   },
      // },
      //element: <DataRegistry />, instead could pass this info thru datasources dashboard route component ?
      element: <DataSourcesDashboardRoute />,
      id: 'datasourceDashboard',
      // async lazy() {
      //   let DataSourcesDashboardRoute = await import('./DataSourcesDashboardRoute');
      //   return { Component: DataSourcesDashboardRoute.default };
      // },
      loader: async () => {
        const { data } = await getDataRegistry();
        registryActions.init(data);
        return { registry: data };
      },
    },
    {
      path: '/projects/:projectId/datasources/:id',
      loader: ({ params: { id } }) => {
        return dataSources.find(dataSource => dataSource.id === id);
      },
      handle: {
        crumb: data => {
          return ['Data', data.label];
        },
      },
      id: 'datasource',
      async lazy() {
        let DataSourceRoute = await import('./DataSourceRoute');
        return { Component: DataSourceRoute.default };
      },
    },
  ],
};
export default dataSourceRouter;
