import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const Tabs = ({ tabs }) => (
  <div className="flex">
    <nav className="flex menu-transition">
      <ul className="flex text-center gap-x-4 pt-1">
        {tabs.map(({ label, url, end, icon, target }) => (
          <li key={label} className="flex items-center">
            <NavLink
              to={url}
              role="tab"
              end={end}
              target={target}
              className={({ isActive }) =>
                `group flex items-center p-2 text-sm font-semibold rounded hover:text-[#639EFF] ${isActive ? 'rounded-b-none text-[#639EFF] after:content-[""] after:h-[3px] after:bg-[#639EFF] after:w-full after:absolute after:bottom-0 after:left-0' : 'text-gray-500'}`
              }>
              {({ isActive }) => (
                <>
                  {icon}
                  <span
                    className={`${isActive ? 'text-black' : 'text-gray-500'} group-hover:text-black group-active:text-black`}>
                    {label}
                  </span>
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
};

export default Tabs;
