import { patchSystem, runSystem, getSystem } from '@/api/systems';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { SYSTEM_STATUS, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER } from '@/consts';
import { keyBy, runInterval } from '@/utils/miscUtils';
import { prepareConversionParams, setConversions } from '@/utils/paramsUtils';
import { resourcesActions } from './resourcesStore';

const initialValues = {
  id: null,
  status: null,
  params: null,
  name: null,
  param_values: null,
  results: null,
  system_type: null,
  rows: null,
};

const useSystemStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useSystemStore;

// actions
export const systemActions = {
  init: async system => {
    systemActions.clear();
    const { id, status, params, name, param_values, results, system_type, param_conversions } = system;

    params.forEach(param => setConversions(param, param_conversions));
    set({ id, status, params, name, param_values, results, system_type });
    if (status === SYSTEM_STATUS.running) {
      systemActions.checkSystemStatusPeriodically();
    }
  },
  patchParams: async paramValues => {
    const { id: systemId } = get();
    const { conversion, ...params } = paramValues;
    const paramsWithConversions = prepareConversionParams(params, conversion);
    delete paramsWithConversions.mc_params;

    await patchSystem(systemId, { param_values: paramsWithConversions });
    set({ param_values: paramsWithConversions });
  },

  run: async () => {
    const { id, params, name, system_type } = get();
    await runSystem(id, { system_type, params, name });
    set({ status: SYSTEM_STATUS.running });
    resourcesActions.updateSystem({ id, status: SYSTEM_STATUS.running });
    systemActions.checkSystemStatusPeriodically();
  },

  checkSystemStatus: async () => {
    const { id } = get();

    try {
      const { data: system } = await getSystem(id);
      const { status } = system;

      if (status === SYSTEM_STATUS.succeeded || status === SYSTEM_STATUS.failed) {
        resourcesActions.updateSystem({ id, status });
        systemActions.init(system);
        return true;
      }
    } catch (err) {
      set({ status: SYSTEM_STATUS.failed });
      resourcesActions.updateSystem({ id, status: SYSTEM_STATUS.failed });
      return true;
    }

    return false;
  },

  checkSystemStatusPeriodically: () => {
    runInterval(systemActions.checkSystemStatus, CHECK_FIRST_DELAY, CHECK_SECOND_DELAY, CHANGE_DELAY_AFTER);
  },
  clear: () => set(initialValues, true),
};

// selectors
export const useSystemParams = () => useSystemStore(store => store.params);
export const useSystemValues = () => useSystemStore(store => store.param_values);
export const useSystemStatus = () => useSystemStore(store => store.status);
export const useSystemResults = () => useSystemStore(store => store?.results);
export const useSystemId = () => useSystemStore(store => store?.id);
export const useSystemName = () => useSystemStore(store => store?.name);
export const useSystemType = () => useSystemStore(store => store?.system_type);

export const useSystemLocations = () =>
  useSystemStore(store => {
    const { params, param_values } = store;
    const locationParams = params?.filter(param => param.type === 'location');
    const locationParamsByName = keyBy(locationParams, 'name');

    if (!param_values.if_different_location_solar) {
      delete locationParamsByName.location_solar;
    }

    if (!param_values.if_different_location_wind) {
      delete locationParamsByName.location_wind;
    }

    return Object.values(locationParamsByName).map(param => param_values[param.name] || param.defaults[0].value) ?? [];
  });

export default useSystemStore;
