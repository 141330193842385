import { RouterProvider } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/initializers/xhr';
import '@/initializers/sentry';
import '@/initializers/refreshAuth';
import '@/initializers/onlineStatus';

import router from '@/router';

import 'react-toastify/dist/ReactToastify.css';

import '@xyflow/react/dist/style.css';

import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
