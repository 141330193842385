import { ANALYSIS_STATUS, SYSTEM_STATUS } from '@/consts';

import {
  Squares2X2Icon,
  ChartBarIcon,
  CubeIcon,
  ArrowsRightLeftIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline';
import config from '@/config';
const { featureFlags } = config;

const getPathwayUrl = (data, location) => {
  const {
    project: { id },
    pathways: { results: pathways },
    systems: { results: systems },
  } = data;
  let pathwayUrl = `/projects/${id}/build`;

  const { state, pathname } = location;
  const rootBuildPath = pathname.match(/build$/);
  const buildPath = pathname.match(/build/);

  if (buildPath && !rootBuildPath) {
    return pathname;
  }

  if (state?.pathwayUrl) {
    pathwayUrl = state?.pathwayUrl;
  } else {
    if (pathways?.length) {
      pathwayUrl = `/projects/${id}/build/pathways/${pathways?.[0]?.id}`;
    } else if (systems?.length) {
      pathwayUrl = `/projects/${id}/build/systems/${systems?.[0]?.id}`;
    }
  }

  return pathwayUrl;
};

const getAnalyzeUrl = (data, location, params) => {
  const {
    project: { id },
    pathways: { results: pathways },
    systems: { results: systems },
  } = data;
  const { pathname } = location;
  const { pathwayId } = params;
  const analyses = pathways.filter(
    pathway => !pathway.analysis_stale && pathway.analysis_status === ANALYSIS_STATUS.succeeded,
  );

  const rootAnalysesPath = pathname.match(/analysis$/);
  const analysesPath = pathname.match(/analysis/);

  const succeededSystems = systems.filter(system => system.status === SYSTEM_STATUS.succeeded);

  if (analysesPath && !rootAnalysesPath) {
    return pathname;
  }

  if (analyses?.length) {
    if (pathwayId && analyses.find(pathway => pathway.id === pathwayId)) {
      return `/projects/${id}/analysis/pathways/${pathwayId}`;
    }
    return `/projects/${id}/analysis/pathways/${analyses[0].id}`;
  }

  if (succeededSystems?.length) {
    return `/projects/${id}/analysis/systems/${succeededSystems[0].id}`;
  }

  return `/projects/${id}/analysis`;
};

const getCompareUrl = (data, location) => {
  const { pathname } = location;
  const {
    project: { id },
    comparisons: { results: comparisons },
  } = data;

  const rootComparisonsPath = pathname.match(/comparisons$/);
  const comparisonsPath = pathname.match(/comparisons/);

  if (comparisonsPath && !rootComparisonsPath) {
    return pathname;
  }

  if (comparisons?.length) {
    return `/projects/${id}/comparisons/${comparisons[0].id}`;
  }

  return `/projects/${id}/comparisons`;
};

export const getTabs = (location, data, params) => {
  const {
    project: { id },
  } = data;

  const pathwayUrl = getPathwayUrl(data, location);
  const analyzeUrl = getAnalyzeUrl(data, location, params);
  const compareUrl = getCompareUrl(data, location);

  return [
    {
      icon: <Squares2X2Icon className="mr-2 h-5 w-5" />,
      url: `/projects/${id}/dashboard`,
      label: 'Dashboard',
      target: '_self',
      end: false,
    },
    {
      icon: <CubeIcon className="mr-2 h-5 w-5" />,
      url: pathwayUrl,
      label: 'Build',
      target: '_self',
      end: false,
    },
    {
      icon: <ChartBarIcon className="mr-2 h-5 w-5" />,
      url: analyzeUrl,
      label: 'Analyze',
      target: '_self',
      end: false,
    },
    {
      icon: <ArrowsRightLeftIcon className="mr-2 w-5 h-5" />,
      url: compareUrl,
      label: 'Compare',
      target: '_self',
      end: false,
    },
    ...(featureFlags.enableDatasource === 'true'
      ? [
          {
            icon: <DocumentChartBarIcon className="mr-2 w-5 h-5" />,
            url: `/projects/${id}/datasources/data_dashboard`,
            label: 'Data',
            target: '_blank',
            end: false,
          },
        ]
      : []),
  ];
};
