import { userActions } from '@/stores/userStore';

// refresh auth token every hour
setInterval(() => userActions.refresh(), 360000);

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    userActions.refresh();
  }
});
