import PropTypes from 'prop-types';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { MapIcon } from '@heroicons/react/24/outline';
import SystemsIcon from '@/assets/react-icons/md/SystemsIcon.svg?react';
import { SYSTEM_STATUS } from '@/consts';

const SystemAnalysesList = ({ systems }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const goToSystem = (event, system) => {
    event.preventDefault();
    navigate(`/projects/${projectId}/build/systems/${system.id}`);
  };

  return (
    <>
      <button className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50">
        <SystemsIcon className="h-4 w-4" />
        Systems
      </button>
      <ul className="mt-1 px-1">
        {systems
          .filter(system => system.status === SYSTEM_STATUS.succeeded)
          .map(system => (
            <li key={system.id} className="group pl-2">
              <NavLink
                className={({ isActive }) =>
                  `flex items-center justify-between block rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}`
                }
                state={{ pathwayUrl: `/projects/${projectId}/build/systems/${system.id}` }}
                to={`/projects/${projectId}/analysis/systems/${system.id}`}
              >
                <span className="truncate">{system.name}</span>
                <div className="group-hover:flex absolute right-5 hidden bg-canvas">
                  <MapIcon onClick={event => goToSystem(event, system)} className="h-5 w-5" />
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
    </>
  );
};

SystemAnalysesList.propTypes = {
  project: PropTypes.object,
  systems: PropTypes.arrayOf(PropTypes.object),
};

export default SystemAnalysesList;
