import MembersList from '@/components/members/MembersList';
import { useRouteLoaderData, Outlet, Link } from 'react-router-dom';
import List from '@/components/core/List';
import { ANALYSIS_STATUS } from '@/consts';
import { SYSTEM_STATUS } from '@/consts';
import PathwayActionsDropdown from '@/components/pathways/PathwayActionsDropdown';
import SystemActionsDropdown from '@/components/systems/SystemActionsDropdown';

// eslint-disable-next-line react/display-name
const pathwayFormatter = (projectId, type) => (pathway, index) => (
  <tr key={index} className="bg-white">
    <td>
      <Link
        className="group flex justify-start rounded-md py-2 px-4 text-sm items-center leading-6 text-gray-700 hover:bg-gray-50"
        to={`/projects/${projectId}/build/${type}/${pathway.id}`}
      >
        <span className="truncate w-2/5">{pathway.name}</span>
        <span className="w-2/5 flex group-hover:hidden">
          {(pathway.analysis_status === ANALYSIS_STATUS.succeeded && !pathway.analysis_stale && (
            <div className="inline-flex rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              Analysis Complete
            </div>
          )) || (
            <div className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              In Progress
            </div>
          )}
        </span>
        <span className="w-2/5 group-hover:flex hidden">
          {(pathway.analysis_status === ANALYSIS_STATUS.succeeded && !pathway.analysis_stale && (
            <Link to={`/projects/${projectId}/analysis/pathways/${pathway.id}`} className="btn btn-xs btn-secondary"> 
              View Analysis
            </Link>
          )) || (
            <Link to={`/projects/${projectId}/build/pathways/${pathway.id}`} className="btn btn-xs btn-secondary"> 
              Continue Build
            </Link>
          )}
        </span>
        <div className="w-1/5 flex justify-end items-center group-hover:flex hidden">
          <PathwayActionsDropdown pathway={pathway} />
        </div>
      </Link>
    </td>
  </tr>
);

// eslint-disable-next-line react/display-name
const systemFormatter = projectId => (system, index) => (
  <tr key={index} className="bg-white">
    <td>
      <Link
        className="group flex justify-start rounded-md items-center py-2 px-4 text-sm leading-6 text-gray-700 hover:bg-gray-100"
        to={`/projects/${projectId}/build/systems/${system.id}`}
      >
        <span className="truncate w-2/5">{system.name}</span>
        <span className="w-2/5 flex group-hover:hidden">
          {(system.status === SYSTEM_STATUS.succeeded && (
            <div className="inline-flex rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              Analysis Complete
            </div>
          )) || (
            <div className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
              In Progress
            </div>
          )}
        </span>
        <span className="w-2/5 group-hover:flex hidden">  
          {(system.status === SYSTEM_STATUS.succeeded && (
            <Link to={`/projects/${projectId}/analysis/systems/${system.id}`}
            className="btn btn-xs btn-secondary"
            > 
            View Analysis
            </Link> 
          )) || (
            <Link to={`/projects/${projectId}/build/systems/${system.id}`} className="btn btn-xs btn-secondary"> 
              Continue Build
            </Link>
          )}
        </span>
        <div className="w-1/5 flex justify-end items-center group-hover:flex hidden">
          <SystemActionsDropdown system={system} />
        </div>
      </Link>
    </td>
  </tr>
);

const ProjectHomeRoute = () => {
  const {
    project: { id, members },
    pathways: { results: pathways },
    systems: { results: systems },
  } = useRouteLoaderData('project');

  return (
    <>
      <div className="container mx-auto pt-12">
        <div className="flex">
          <div className="w-2/3 p-4">
            <div className="flex items-center justify-between pb-2 border-b mr-6 border-gray-200">
              <p className="text-lg font-semibold"> Pathways ({pathways.length})</p>
              <Link to={`pathways/new`} className="btn btn-secondary btn-xs">
                Add Pathway
              </Link>
            </div>
            <List className="mt-2" items={pathways} itemFormatter={pathwayFormatter(id, 'pathways')} />

            <div className="flex items-center justify-between pb-2 border-b mr-6 border-gray-200 mt-6">
              <p className="text-lg font-semibold">Systems ({systems.length})</p>
              <Link to={`systems/new`} className="btn btn-secondary btn-xs">
                Add System
              </Link>
            </div>
            <List className="mt-2 mb-12" items={systems} itemFormatter={systemFormatter(id)} />
          </div>
          <div className="w-1/3 p-4">
            <div className="flex items-center justify-between pb-2 mr-6 border-b border-gray-200">
              <div className="flex items-center">
                <p className="pr-2 text-lg font-semibold">Members ({members.length})</p>
              </div>
              <Link to={`new-member`} className="btn btn-secondary btn-xs">
                Add Member
              </Link>
            </div>
            <MembersList members={members} />
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default ProjectHomeRoute;
