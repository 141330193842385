import { userActions } from '@/stores/userStore';
import { ArrowLeftStartOnRectangleIcon, Cog8ToothIcon, DocumentIcon, BriefcaseIcon } from '@heroicons/react/24/outline';
import { useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const AccountActionsDropdown = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const logout = () => {
    close();
    userActions.logout();
    navigate('/login');
  };

  const close = () => {
    ref?.current?.removeAttribute('open');
  };

  return (
    <ul className="menu menu-end menu-horizontal pr-4">
      <li>
        <details ref={ref}>
          <summary tabIndex={0}>Account</summary>
          <ul className="p-2 bg-base-100 rounded-t-none z-1000">
            <li>
              <Link onClick={close} to="/settings" state={{ pathname }}>
                <Cog8ToothIcon className="w-5 h-5" />
                Settings
              </Link>
            </li>
            <li>
              <Link onClick={close} to="/">
                <BriefcaseIcon className="w-5 h-5" />
                Projects
              </Link>
            </li>

            <li>
              <a href="https://docs.sesame.software" target="_blank" rel="noreferrer">
                <DocumentIcon className="w-5 h-5" />
                Docs
              </a>
            </li>

            <li>
              <a
                className="text-sm cursor-pointer rounded leading-6 flex items-center pr-4 hover:bg-gray-100"
                onClick={logout}
              >
                <ArrowLeftStartOnRectangleIcon className="w-5 h-5" />
                Logout
              </a>
            </li>
          </ul>
        </details>
      </li>
    </ul>
  );
};

export default AccountActionsDropdown;
