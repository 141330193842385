import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useEffect } from 'react';
import { getUnitOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { useUnits } from '@/stores/comparisonStore';

const TEAComparisonForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const units = useUnits();
  const unitOptions = getUnitOptions(units);
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  // submit form on every input change
  useEffect(() => {
    const subscription = watch(debouncedSave);
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave]);

  return (
    <div className="group node">
      <FormProvider {...methods}>
        <form>
          <div className="mt-2 grid grid-cols-5 gap-2">
            <FormRow className="text-xs mb-2 overflow-hidden" label="Unit" name="levelizationUnit">
              <Select className="w-full truncate" name="levelizationUnit" options={unitOptions} />
            </FormRow>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

TEAComparisonForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default TEAComparisonForm;
