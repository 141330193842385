import { Link, useParams, useLoaderData, Outlet } from 'react-router-dom';
import { prepareFormData } from '@/utils/paramsUtils';
import { caseActions, useCaseParamsForNode, useCaseMcParamsForNode } from '@/stores/caseStore';
import { XMarkIcon } from '@heroicons/react/24/outline';
import NodeForm from './NodeForm';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const NodeModal = () => {
  const { projectId, pathwayId } = useParams();
  const { reset } = useFormContext();
  const { node } = useLoaderData();
  const caseParams = useCaseParamsForNode(node.id);
  const caseMcParams = useCaseMcParamsForNode(node.id);
  const [isFormReady, setFormReady] = useState(false);

  const { params, categories, defaultValues } = prepareFormData(node.params, { ...caseParams, ...caseMcParams });

  // Execute reset before the first render
  useEffect(() => {
    setFormReady(false);
    reset(defaultValues);
    setTimeout(() => setFormReady(true), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.id]);

  const saveParams = params => {
    caseActions.patchParams(node.id, params);
  };

  if (!isFormReady) {
    return null;
  }

  if (node.description) {
    categories.Description = {
      categoryName: 'Info',
      params: [],
    };
  }

  return (
    <div className="group node h-full relative">
      <NodeForm
        key={node.id}
        actions={
          <Link to={`/projects/${projectId}/build/pathways/${pathwayId}`}>
            <XMarkIcon className="h-5 w-5" />
          </Link>
        }
        title={node.label}
        description={node.description}
        params={params}
        categories={categories}
        onSubmit={saveParams}
      />
      <div className="absolute w-full top-0 z-1000 bg-white">
        <Outlet />
      </div>
    </div>
  );
};

export default NodeModal;
