import Layout from '@/components/core/Layout';
import SettingsList from './SettingsList';
import { Outlet, useLoaderData } from 'react-router-dom';
import AppVersion from '@/components/core/AppVersion';

const SettingsRoute = () => {
  const user = useLoaderData();

  return (
    <Layout>
      <div className="flex m-4 mt-8">
        <div className="avatar placeholder">
          <div className="bg-neutral text-neutral-content rounded-full w-12">
            <span className="text-xl">{user.name[0]}</span>
          </div>
        </div>
        <div className="flex-1 ml-4">
          <div>{user.name}</div>
          <div>{user.email}</div>
        </div>
      </div>
      <hr />
      <div className="flex">
        <div className="w-64 relative flex flex-col">
          <SettingsList />
        </div>

        <div className="w-full flex flex-col m-4 ml-8">
          <Outlet />
        </div>
      </div>
      <AppVersion />
    </Layout>
  );
};

export default SettingsRoute;
