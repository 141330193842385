import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import { getIOOptions, getUnitsByIO, getEmissionTypes, getNodeOptions, getUnitOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';

const levelizeOptions = [
  { value: false, label: 'Total' },
  { value: true, label: 'Per Functional Unit' },
];

const LCAPlotForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, register, control, setValue } = methods;
  const nodes = useNodes();
  const nodeOptions = getNodeOptions(nodes);

  const [nodeId, levelize, levelizeBy] = useWatch({
    control,
    name: ['nodeId', 'levelize', 'levelizeBy'],
    defaultValue: defaultValues,
  });

  const node = useNodeById(nodeId);
  const unitsByIO = getUnitsByIO(node);
  const levelizeByOptions = getIOOptions(node);
  const defaultLevelizeBy = levelizeByOptions?.[0]?.value;
  const levelizeUnitOptions = getUnitOptions(unitsByIO?.[defaultLevelizeBy]?.units);
  const defaultLevelizeUnit = levelizeUnitOptions?.[0].value;
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const emissionTypeOptions = getEmissionTypes(nodes);
  const defaultEmissionType = emissionTypeOptions?.[0]?.value;

  // when node is changed update default levelizeBy
  useEffect(() => {
    setValue('levelizeBy', defaultLevelizeBy);
  }, [defaultLevelizeBy, setValue]);

  // when levelizeBy is changed update the default levelizeUnit
  useEffect(() => {
    if (defaultLevelizeBy !== levelizeBy) {
      setValue('levelizeUnit', defaultLevelizeUnit);
    }
  }, [defaultLevelizeUnit, levelizeBy, defaultLevelizeBy, setValue]);

  // when node is changed update default emissionType
  useEffect(() => {
    setValue('emissionType', defaultEmissionType);
  }, [nodeId, defaultEmissionType, setValue]);

  // submit form on every input change
  useEffect(() => {
    const subscription = watch(debouncedSave);
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave]);

  return (
    <div className="group node">
      <FormProvider {...methods}>
        <form>
          <div className="mt-2 grid grid-cols-3 gap-2">
            <div>
              <FormRow className="text-xs mb-2 overflow-hidden" label="Module" name="nodeId">
                <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
              </FormRow>
            </div>
            <div>
              <FormRow className="text-xs mb-2 overflow-hidden" label="Values" name="levelize">
                <Select className="w-full truncate" name="levelize" options={levelizeOptions} />
              </FormRow>
            </div>
            <div>
              <FormRow className="text-xs mb-2 overflow-hidden" label="Y axis" name="emissionType">
                <Select className="w-full truncate" name="emissionType" options={emissionTypeOptions} />
              </FormRow>
            </div>
            <div>
              <FormRow className="mb-2 overflow-hidden" label="Add Scope 3 breakdown" name="scope3Breakdown">
                <input
                  {...register('scope3Breakdown')}
                  id="scope3Breakdown"
                  name="scope3Breakdown"
                  type="checkbox"
                  className="toggle toggle-sm toggle-success mt-1 w-full truncate"
                />
              </FormRow>
            </div>
            <FormRow
              className={`mb-2 ${levelize !== 'true' ? 'hidden' : ''}`}
              label="Functional Unit"
              name="levelizeBy"
            >
              <div className="flex join items-center overflow-hidden w-full rounded-none">
                <Select className="join-item w-full truncate" name="levelizeBy" options={levelizeByOptions} />
                <Select className="join-item truncate" name="levelizeUnit" options={levelizeUnitOptions} />
              </div>
            </FormRow>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

LCAPlotForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default LCAPlotForm;
