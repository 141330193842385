import { getStack } from '@/utils/stackUtils';
import * as Sentry from '@sentry/react';

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = getStack();

const ignoreErrors = [/status code 400/im, /Request failed with status code 401/im];

if (dsn) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.feedbackIntegration({
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
      }),
    ],
    ignoreErrors,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment,
  });
}
