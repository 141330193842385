import usePrevious from '@/hooks/usePrevious';
import { calculateDistributionRange } from '@/utils/paramsUtils';
import { buildMcValidators } from '@/utils/validators';
import { useFormContext, useWatch } from 'react-hook-form';

const useMcParams = param => {
  const { name, initValidators, defaultValue, conversion, baseConversion } = param;
  const minName = `mc_params.${name}.min`;
  const maxName = `mc_params.${name}.max`;
  const { control, getValues } = useFormContext();
  const conversionStr = useWatch({ control, name: `conversion.${name}`, disabled: !conversion });
  const mainValue = useWatch({
    control,
    name,
    defaultValue,
  });
  const prevMainValue = usePrevious(mainValue);
  const currentConversion = conversionStr ? JSON.parse(conversionStr) : null;
  const { min, max } = calculateDistributionRange(param, mainValue, currentConversion);

  let minDefaultValue = getValues(minName) ?? min;
  let maxDefaultValue = getValues(maxName) ?? max;

  let minValidators = buildMcValidators(initValidators, mainValue, 'min', baseConversion, currentConversion);
  let maxValidators = buildMcValidators(initValidators, mainValue, 'max', baseConversion, currentConversion);

  if (
    prevMainValue !== undefined &&
    mainValue !== prevMainValue &&
    (mainValue < minDefaultValue || mainValue > maxDefaultValue || prevMainValue === 0)
  ) {
    const distributionRange = calculateDistributionRange(param, mainValue, currentConversion);

    minDefaultValue = distributionRange.min;
    maxDefaultValue = distributionRange.max;
  }

  return {
    min: {
      name: minName,
      validators: minValidators,
      defaultValue: minDefaultValue,
    },
    max: {
      name: maxName,
      validators: maxValidators,
      defaultValue: maxDefaultValue,
    },
  };
};

export default useMcParams;
