import { useCallback } from 'react';
import { ReactFlow, Controls, useReactFlow, MiniMap, Background, BackgroundVariant } from '@xyflow/react';

import { useEdges, useNodes, pathwayActions, usePathwayId } from '@/stores/pathwayStore';

import PathwayPanel from './PathwayPanel';
import { proOptions, nodeTypes, edgeTypes, fitViewOptions } from './pathwayOptions';
import { pathwaysCacheActions, useCachedPanePosition } from '@/stores/pathwaysCacheStore';

const PathwayFlow = () => {
  const pathwayId = usePathwayId();
  const nodes = useNodes();
  const edges = useEdges();
  const reactFlow = useReactFlow();
  const position = useCachedPanePosition(pathwayId);

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();
      const nodeType = event.dataTransfer.getData('nodeType');

      if (!nodeType) {
        return;
      }

      const position = reactFlow.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      pathwayActions.addNode(nodeType, position);
    },
    [reactFlow],
  );

  const onMoveEnd = (event, data) => {
    pathwaysCacheActions.cachePanePosition(pathwayId, data);
  };

  const props = position ? { defaultViewport: position } : { fitViewOptions, fitView: true };

  return (
    <div key={pathwayId} className="flex-grow w-full h-full pl-2 pt-2">
      
      <ReactFlow
        nodes={nodes}
        edges={edges}
        deleteKeyCode={''}
        onNodesChange={pathwayActions.onNodesChange}
        onEdgesChange={pathwayActions.onEdgesChange}
        onConnect={pathwayActions.connectInputToOutput}
        onDrop={onDrop}
        onMoveEnd={onMoveEnd}
        onDragOver={onDragOver}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        {...props}
        minZoom={0.2}
      >
        <Background color="#9c9c9c" variant={BackgroundVariant.Dots} />
        <Controls showInteractive={false} />
        <MiniMap zoomable pannable />
        <PathwayPanel />
      </ReactFlow>
    </div>
  );
};

export default PathwayFlow;
