import AppVersion from '@/components/core/AppVersion';
import ProjectHeader from '@/components/core/ProjectHeader';
import { Outlet } from 'react-router-dom';

const ProjectRoute = () => (
  <div className="h-screen">
    <div className="h-full flex">
      <div className="w-full h-full flex flex-col">
        <ProjectHeader />
        <div className="flex w-full h-full overflow-y-scroll">
          <Outlet />
        </div>
      </div>
    </div>
    <AppVersion />
  </div>
);

export default ProjectRoute;
