import { useLayoutEffect, useState } from 'react';

const usePixelsToPercentage = (pixels = 160) => {
  const [percentage, setPercentage] = useState(0);

  useLayoutEffect(() => {
    const updatePercentage = () => {
      const percentage = (pixels * 100) / window.innerWidth;
      setPercentage(percentage);
    };

    updatePercentage();
    window.addEventListener('resize', updatePercentage);

    return () => window.removeEventListener('resize', updatePercentage);
  }, [pixels]);

  return percentage;
};

export default usePixelsToPercentage;
