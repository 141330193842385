import PathwayAnalysesList from '@/components/analyses/PathwayAnalysesList';
import SystemAnalysesList from '@/components/analyses/SystemAnalysesList';
import { Await, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';
import ListPane from '@/components/core/ListPane';
import MenuPane from '@/components/core/MenuPane';
import { Suspense, useEffect } from 'react';
import { menuAction } from '@/stores/menuStore';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useLocation } from 'react-router-dom';
import EmptyState from '@/components/pathways/EmptyState';

const AnalysesRoute = () => {
  const data = useLoaderData();
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const displayEmptyState = pathname.match(/analysis$/);

  useEffect(() => {
    menuAction.open();
  }, []);

  return (
    <Suspense fallback={<></>}>
      <Await resolve={data.promise}>
        {([pathways, systems]) => (
          <PanelGroup direction="horizontal">
            <MenuPane>
              <ListPane>
                <div className="mb-2">
                  <PathwayAnalysesList pathways={pathways?.data?.results} />
                </div>
                <div className="mb-2">
                  <SystemAnalysesList systems={systems?.data?.results} />
                </div>
              </ListPane>
            </MenuPane>
            <Panel className="w-full !overflow-y-scroll bg-canvas">
              {displayEmptyState ? (
                <EmptyState title="In order to view an analysis you will need to build, balance and run a pathway.">
                  <NavLink
                    to={`/projects/${projectId}/build/`}
                    className="btn btn-primary rounded-md leading-6 font-semibold flex"
                  >
                    View Pathways
                  </NavLink>
                </EmptyState>
              ) : (
                <Outlet />
              )}
            </Panel>
          </PanelGroup>
        )}
      </Await>
    </Suspense>
  );
};

export default AnalysesRoute;
