import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { getEmissionTypes, getNodeOptions } from '@/utils/pathwayUtils';
import { FLOW_RATE_TYPES } from '@/consts';

const BalanceForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, control, setValue } = methods;
  const nodes = useNodes();
  const nodeOptions = getNodeOptions(nodes);

  const [nodeId] = useWatch({
    control,
    name: ['nodeId'],
    defaultValue: defaultValues,
  });

  const emissionTypeOptions = getEmissionTypes(nodes);
  const defaultEmissionType = emissionTypeOptions?.[0]?.value;

  // when node is changed update default emissionType
  useEffect(() => {
    setValue('emissionType', defaultEmissionType);
  }, [nodeId, defaultEmissionType, setValue]);

  // submit form on every input change
  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit]);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form>
          <div className="mt-2 grid grid-cols-3 gap-2">
            <FormRow className="text-xs mb-2 overflow-hidden w-full" label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
            <FormRow className="overflow-hidden" label="Emissions Type" name="emissionType">
              <Select className="w-full truncate" name="emissionType" options={emissionTypeOptions} />
            </FormRow>
            <FormRow className="overflow-hidden" label="Time Unit" name="timeUnit">
              <Select
                className="w-full truncate"
                name="timeUnit"
                registerOptions={{ valueAsNumber: true }}
                options={FLOW_RATE_TYPES}
              />
            </FormRow>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

BalanceForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default BalanceForm;
