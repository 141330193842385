import { useTempAnchor } from '@/stores/caseStore';
import { useCaseId } from '@/stores/pathwayStore';
import { useEffect } from 'react';
import useNodeStore, { nodeActions } from '@/stores/nodeStore';
import AnchorFormDialog from './AnchorFormDialog';

const AnchorContainer = () => {
  const tempAnchor = useTempAnchor();
  const caseId = useCaseId();
  const nodeId = tempAnchor?.id;
  const nodeInfo = useNodeStore();

  // load node info
  useEffect(() => {
    if (caseId && nodeId) {
      nodeActions.loadInfo(caseId, nodeId);
    }
  }, [caseId, nodeId]);

  if (!tempAnchor || !nodeInfo) {
    return null;
  }

  return <AnchorFormDialog />;
};

export default AnchorContainer;
