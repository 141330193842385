import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  project: null,
  pathways: null,
  systems: null,
  comparisons: null,
};

const useResourcesStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useResourcesStore;

// actions
export const resourcesActions = {
  setResources: resources => set(resources),
  getPathways: () => get().pathways,
  getSystems: () => get().systems,

  updatePathway: params => {
    const { pathways } = get();
    const index = pathways?.findIndex(p => p.id === params.id);

    if (index >= 0) {
      const pathway = { ...pathways[index], ...params };
      set(state => {
        state.pathways[index] = pathway;
      });
    }
  },

  updateSystem: params => {
    const { systems } = get();
    const index = systems?.findIndex(s => s.id === params.id);

    if (index >= 0) {
      const system = { ...systems[index], ...params };

      set(state => {
        state.systems[index] = system;
      });
    }
  },
};

export const usePathways = () => useResourcesStore(store => store.pathways);
export const useSystems = () => useResourcesStore(store => store.systems);
export const useComparisons = () => useResourcesStore(store => store.comparisons);
export const useProject = () => useResourcesStore(store => store.project);

export default useResourcesStore;
