import { exportCaseData } from '@/api/cases';
import { useAnalysis } from '@/stores/analysisStore';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import kebabCase from 'just-kebab-case';

const ExportDataButton = () => {
  const analysis = useAnalysis();

  const download = async event => {
    event.preventDefault();
    const { data } = await exportCaseData(analysis.caseId);
    const objectUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = objectUrl;
    link.download = `${kebabCase(analysis.name)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={download} className="absolute right-5 top-5 btn btn-sm btn-secondary">
      <ArrowDownTrayIcon className="w-5 h-5" />
      Export Data
    </button>
  );
};

export default ExportDataButton;
