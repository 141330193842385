import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import { arrayToObject } from '@/utils/miscUtils';
import ErrorMessage from './ErrorMessage';
import ParameterFields from '../ParameterFields';
import Accordion from '@/components/core/Accordion';

const BlockField = ({ param }) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { name, params, validators: validate } = param;
  const errorMessage = errors?.[name]?.root?.message;
  const { fields, append, remove } = useFieldArray({
    control,
    rules: { validate },
    name,
  });

  const addBlock = () => {
    const block = arrayToObject(params, 'name', 'defaultValue');
    append(block);
  };

  const removeBlock = index => remove(index);

  return (
    <>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Accordion title={field.label || param.name}>
            <div className="pl-2 pr-2">
              <ParameterFields index={index} params={params} parentName={name} field={field} />
            </div>
            <div className="col-span-2 flex justify-center items-center flex-col border-b pb-2">
              <button onClick={() => removeBlock(index)} className="btn btn-sm btn-circle btn-ghost">
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </Accordion>
        </Fragment>
      ))}
      <div className="col-span-2 flex justify-center flex-col items-center pb-2">
        <ErrorMessage text={errorMessage} />
        <button onClick={addBlock} className="btn btn-sm btn-circle btn-ghost">
          {<PlusIcon className="h-5 w-5" />}
        </button>
      </div>
    </>
  );
};

BlockField.propTypes = {
  param: PropTypes.object,
};

export default BlockField;
