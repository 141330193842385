import PropTypes from 'prop-types';

const List = ({ items, itemFormatter, className = '' }) => (
  <table className={`w-full text-left text-sm ${className}`}>
    <tbody>{items.map((item, index) => itemFormatter(item, index, items))}</tbody>
  </table>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  itemFormatter: PropTypes.func,
  className: PropTypes.string,
};

export default List;
