import filterNodeTypes from '@/utils/filterNodeTypes';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  searchParams: {},
  activeCategory: null,
  nodeTypesByCategory: {},
  productList: [],
};

const useRegistryStore = create()(immer(() => initialValues));

const { setState: set } = useRegistryStore;

// actions
export const registryActions = {
  init: registry => {
    const activeCategory = registry?.[0]?.categories?.[0];
    const allNodes = [];
    const nodeTypesByCategory = registry.reduce((acc, nodeType) => {
      const { categories, search_terms, label } = nodeType;
      const searchIndex = [label, ...search_terms].join(' ');

      nodeType.searchIndex = searchIndex;

      categories?.forEach(category => {
        if (category) {
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(nodeType);
        }
      });

      allNodes.push(nodeType);

      return acc;
    }, {});

    nodeTypesByCategory.All = allNodes;

    // gather all category labels to create a product list
    //     const ioLabels = registry.reduce((acc, nodeType) => {
    //       const { categories, input_types, output_types } = nodeType;
    //       categories?.forEach(category => {
    //         if (category !== 'Generic') {
    //           acc.push(...input_types.filter(input => !input.generic).map(input => input.label));
    //           acc.push(...output_types.filter(output => !output.generic).map(output => output.label));
    //         }
    //       });
    //
    //       return acc;
    //     }, []);
    // const productList = ['Select Product', ...[...new Set(ioLabels)].sort()];

    // TODO: this should be handled by the server
    const productList = [
      'Select Product',
      'Ammonia',
      'CO₂',
      'Coal',
      'Electricity',
      'Hydrogen',
      'Liquid Hydrogen',
      'Liquid Natural Gas',
      'Natural Gas',
      'Nitrogen',
    ];

    set({ nodeTypesByCategory, productList, activeCategory });
  },

  updateSearch: searchParams => set({ searchParams }),
  setActiveCategory: activeCategory => set({ activeCategory }),
  clear: () => set({ searchParams: {} }),
};

export const useProductList = () => useRegistryStore(store => store.productList);
export const useCategories = () => useRegistryStore(store => Object.keys(store.nodeTypesByCategory));
export const useActiveNodeTypes = () =>
  useRegistryStore(store => {
    const { searchParams, nodeTypesByCategory, activeCategory } = store;
    const { term, product } = searchParams;
    let activeNodeTypes = filterNodeTypes(term, activeCategory, product, nodeTypesByCategory);

    return activeNodeTypes;
  });
export const useActiveCategory = () => useRegistryStore(store => store.activeCategory);

export default useRegistryStore;
