import { getNodeInfo } from '@/api/nodes';
import { findConversionByUnit } from '@/utils/unitUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = null;
const useNodeStore = create()(immer(() => initialValues));

const { setState: set } = useNodeStore;

// actions
export const nodeActions = {
  loadInfo: async (caseId, nodeId) => {
    const { data } = await getNodeInfo(caseId, nodeId);
    set({ input: data.inputs, output: data.outputs });
  },
  clear: () => set(initialValues, true),
};

// selectors

export const useConversionsByTypeName = (ioType, ioName) =>
  useNodeStore(store => {
    const { base_unit, conversions = [] } = store?.[ioType]?.[ioName] ?? {};

    return [{ unit: base_unit, factor: 1 }, ...conversions].reduce((acc, conversion) => {
      acc.push(conversion);
      return acc;
    }, []);
  });

export const useConversionsByMeasures = (ioType, ioName) =>
  useNodeStore(store => {
    const { base_unit, conversions = [] } = store?.[ioType]?.[ioName] ?? {};

    return [{ unit: base_unit, factor: 1 }, ...conversions].reduce((acc, conversion) => {
      const measure = conversion?.unit?.measure;

      if (measure) {
        acc[measure] = acc[measure] ?? [];
        acc[measure].push(conversion);
      }

      return acc;
    }, {});
  });

export const useFindConversion = (ioType, ioName, unit) => {
  return useNodeStore(store => {
    const { base_unit: baseUnit, conversions = [] } = store?.[ioType]?.[ioName] ?? {};

    // if unit is not present on the case return base unit
    if (!unit) {
      return { unit: baseUnit, factor: 1 };
    }

    const conversion = findConversionByUnit(conversions, unit);

    return conversion ?? { unit: baseUnit, factor: 1 };
  });
};

export default useNodeStore;
