import PropTypes from 'prop-types';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ANALYSIS_STATUS } from '@/consts';
import PathwaysIcon from '@/assets/react-icons/md/PathwaysIcon.svg?react';
import PathwayActionsDropdown from './PathwayActionsDropdown';
import { usePathways } from '@/stores/resourcesStore';

const PathwayList = () => {
  const pathways = usePathways();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/build/pathways/new`);
  };

  return (
    <>
      <button
        onClick={addNew}
        className="hover:bg-gray-50 p-2 flex items-center w-full text-left rounded-md gap-x-3 text-sm leading-6 font-semibold text-gray-700"
      >
        <PathwaysIcon className="w-4 h-4 shrink-0" />
        <span className="truncate">Pathways</span>
        <PlusIcon className="ml-auto rounded h-6 w-6 bg-gray-100 p-1 shrink-0 text-gray-500 hover:bg-white" />
      </button>
      <ul className="mt-1 px-1">
        {pathways.map(pathway => (
          <li key={pathway.id} className="group pl-2">
            <NavLink
              className={({ isActive }) =>
                `flex items-center justify-between rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-100'}`
              }
              to={`/projects/${projectId}/build/pathways/${pathway.id}`}
            >
              <span className="truncate">{pathway.name}</span>
              {pathway.analysis_status === ANALYSIS_STATUS.succeeded && !pathway.analysis_stale && (
                <div className="group-hover:hidden">
                  <CheckCircleIcon className="text-primary text-green-500 h-4 w-4" />
                </div>
              )}
              <div className="group-hover:flex hidden">
                <PathwayActionsDropdown pathway={pathway} />
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

PathwayList.propTypes = {
  project: PropTypes.object,
};

export default PathwayList;
