import { useCashFlowsPlot, useNodeById } from '@/stores/analysisStore';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import Select from '@/components/core/form/Select';
import { unitToString } from '@/utils/unitUtils';
import CashFlowBarChart from '@/components/charts/CashFlowBarChart';

const DEFAULT_PLOT = 'After Tax Post Depreciation Cash Flow';

const CashFlowChartContainer = () => {
  const plot = useCashFlowsPlot();
  const { results, nodeId } = plot;
  const node = useNodeById(nodeId);
  const plottable = results && results.data.filter(result => result.plottable);
  const yearLabels = results && results.data.find(result => result.category[1] === 'Year');
  const defaultValues = { plotColumn: DEFAULT_PLOT };
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { control } = methods;

  const [plotColumn] = useWatch({
    control,
    name: ['plotColumn'],
    defaultValue: defaultValues,
  });

  const currentPlot = plottable?.find(p => p.category.includes(plotColumn)) || plottable?.[0];

  const category = currentPlot?.category[1];
  const unit = unitToString(currentPlot?.unit);
  const options = plottable?.map(result => ({ value: result.category[1], label: result.category[1] }));

  if (!plottable) {
    return null;
  }

  return (
    <>
      <div className="h-96">
        <CashFlowBarChart
          data={currentPlot.data}
          labels={yearLabels.data}
          yAxisLabel={`${node.label} - ${category}\n${unit}/year`}
        />
      </div>
      <div>
        <FormProvider {...methods}>
          <div className="group node">
            <form>
              <div className="mt-2 grid grid-cols-4 gap-2">
                <FormRow className="overflow-hidden" label="Change Graph" name="plotColumn">
                  <Select name="plotColumn" className="w-full truncate" options={options} />
                </FormRow>
              </div>
            </form>
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default CashFlowChartContainer;
