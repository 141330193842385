import PropTypes from 'prop-types';
import { useCollapsed } from '@/stores/menuStore';
import { Panel } from 'react-resizable-panels';
import { useEffect, useRef, useState } from 'react';
import ResizeHandleVertical from './ResizeHandleVertical';
import usePixelsToPercentage from '@/hooks/usePixelsToPercentage';

const MenuPane = ({ children, minSize = 160 }) => {
  const collapsed = useCollapsed();
  const ref = useRef();
  const [isResizing, setIsResizing] = useState(false);
  const resizingClasses = !isResizing ? 'transition-[flex] duration-200' : '';
  const size = usePixelsToPercentage(minSize);

  useEffect(() => {
    if (collapsed) {
      ref.current.collapse();
    } else {
      ref.current.expand();
    }
  }, [collapsed]);

  return (
    <>
      <Panel
        defaultSize={12}
        maxSize={50}
        minSize={size}
        collapsible
        ref={ref}
        className={`relative flex flex-col bg-base-100 ${resizingClasses}`}
      >
        <div className="p-2 pt-4 overflow-y-auto scrollbar-thin">{children}</div>
      </Panel>
      <ResizeHandleVertical onDragging={e => setIsResizing(e)} />
    </>
  );
};

MenuPane.propTypes = {
  children: PropTypes.node,
  minSize: PropTypes.number,
};

export default MenuPane;
