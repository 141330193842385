import PropTypes from 'prop-types';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { CheckCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ANALYSIS_STATUS } from '@/consts';
import SystemActionsDropdown from './SystemActionsDropdown';
import SystemsIcon from '@/assets/react-icons/md/SystemsIcon.svg?react';
import { useSystems } from '@/stores/resourcesStore';

const SystemList = () => {
  const systems = useSystems();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/build/systems/new`);
  };

  return (
    <>
      <button
        onClick={addNew}
        className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
      >
        <SystemsIcon className="h-4 w-4 shrink-0" />
        <span className="truncate">Systems</span>
        <PlusIcon className="ml-auto rounded h-6 w-6 bg-gray-100 p-1 shrink-0 text-gray-500 hover:bg-white" />
      </button>

      <ul className="mt-1 px-1">
        {systems.map(system => (
          <li key={system.id} className="group pl-2">
            <NavLink
              className={({ isActive }) =>
                `flex items-center justify-between rounded-md py-2 pl-6 pr-2 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}`
              }
              to={`/projects/${projectId}/build/systems/${system.id}`}
            >
              <span className="truncate">{system.name}</span>
              {system.status === ANALYSIS_STATUS.succeeded && (
                <div className="group-hover:hidden">
                  <CheckCircleIcon className="text-primary text-green-500 h-4 w-4" />
                </div>
              )}
              <div className="group-hover:flex hidden">
                <SystemActionsDropdown system={system} />
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

SystemList.propTypes = {
  project: PropTypes.object,
};

export default SystemList;
