import { ANALYSIS_STATUS } from '@/consts';
import { useAnalysisStatus, useIsBalanced, caseActions } from '@/stores/caseStore';
import { PlayIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useFormContext } from 'react-hook-form';
import { useRouteLoaderData } from 'react-router-dom';

const AnalysisButton = () => {
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext();
  const isBalanced = useIsBalanced();
  const status = useAnalysisStatus();
  const nodeData = useRouteLoaderData('node');
  const nodeId = nodeData?.node?.id;

  const saveAndRun = async (data, analysisTypes) => {
    if (isDirty && nodeId) {
      await caseActions.patchParams(nodeId, data);
      await caseActions.balanceCase();
      reset(data);
    }
    caseActions.analyzeCase({ analysis_types: analysisTypes });
  };

  if (!isBalanced) {
    return (
      <button className="btn btn-sm btn-disabled">
        <PlayIcon className="w-5 h-5" />
        Analyze Pathway
      </button>
    );
  }

  if (status === ANALYSIS_STATUS.pending || status === ANALYSIS_STATUS.running) {
    return (
      <button className="btn btn-sm btn-secondary">
        <span className="loading loading-spinner"></span>
        Analysis is {status}
      </button>
    );
  }

  if (status === ANALYSIS_STATUS.failed) {
    return <button className="btn btn-sm btn-error">Analysis failed</button>;
  }

  return (
    <div className="join">
      <button
        type="button"
        onClick={handleSubmit(data => saveAndRun(data, ['lca', 'tea', 'sensitivity']))}
        className={`btn btn-sm join-item ${status === ANALYSIS_STATUS.succeeded ? 'btn-secondary' : 'btn-primary'}`}
      >
        <PlayIcon className="w-5 h-5" />
        Analyze Pathway
      </button>
      <div className="dropdown dropdown-top dropdown-end border-l">
        <div
          tabIndex={0}
          role="button"
          className={`btn btn-sm join-item ${status === ANALYSIS_STATUS.succeeded ? 'btn-secondary' : 'btn-primary'}`}
        >
          <ChevronDownIcon className="w-4 h-5" />
        </div>
        <div
          tabIndex={0}
          className="dropdown-content z-[1] menu shadow-md bg-base-100 w-80 border border-gray-200 mb-1 rounded p-0 divide-y divide-gray-200"
        >
          <button
            className="group hover:bg-blue-600 hover:text-white rounded-t text-left p-2 pl-4 flex flex-col gap-1"
            type="button"
            onClick={handleSubmit(data => saveAndRun(data, ['lca', 'tea', 'sensitivity']))}
          >
            <span className="font-bold">Analyze Pathway</span>
          </button>
          <button
            className="group hover:bg-blue-600 hover:text-white rounded-b text-left p-2 pl-4 flex flex-col gap-1"
            type="button"
            onClick={handleSubmit(data => saveAndRun(data, ['lca', 'tea', 'sensitivity', 'mc']))}
          >
            <span className="font-bold">Analyze with Uncertainty Analysis</span>
            <span className="text-xs text-gray-500 group-hover:text-white">~30 seconds</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnalysisButton;
