import { comparisonActions, useTEAComparisonPlot } from '@/stores/comparisonStore';
import StackedBarChart from '@/components/charts/StackedBarChart';
import { getTEAYaxisLabel } from '@/utils/comparisonUtils';
import { isEmpty } from '@/utils/miscUtils';
import TEAComparisonForm from './TEAComparisonForm';
import TEAComparisonSummary from './TEAComparisonSummary';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const colors = [
  '#639eff',
  '#abc4fb',
  '#ff8f12',
  '#f4bf7b',
  '#5ccc8f',
  '#e74c3c',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#f1c40f',
];

const TEAComparisonPlot = () => {
  const plot = useTEAComparisonPlot();
  const updatePlot = formData => comparisonActions.updatePlot('tea', formData);

  if (isEmpty(plot)) {
    return null;
  }

  const { results } = plot;
  const yAxisLabel = getTEAYaxisLabel(plot);

  return (
    <AnalysisContainer title="Techno-Economic Analysis">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <AnalysisBox>
            <>
              {results && (
                <StackedBarChart
                  colors={colors}
                  rows={results.rows}
                  columns={results.columns}
                  yAxisLabel={yAxisLabel}
                  labelsInCSV={true}
                />
              )}
              <TEAComparisonForm onSubmit={updatePlot} defaultValues={plot} />
            </>
          </AnalysisBox>
        </div>
        <AnalysisBox>
          {results && (
            <TEAComparisonSummary minTotal={results.minTotal} maxTotal={results.maxTotal} unit={yAxisLabel} />
          )}
        </AnalysisBox>
      </div>
    </AnalysisContainer>
  );
};

export default TEAComparisonPlot;
