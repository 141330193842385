import { Form, Link, useLoaderData } from 'react-router-dom';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';

const TitleEditForm = () => {
  const { node } = useLoaderData();

  return (
    <div className="shadow-md p-2 m-2 rounded-sm">
      <Form method="put">
        <div className="flex flex-col gap-1">
          <div>
            <input
              name="name"
              defaultValue={node.label}
              type="text"
              className="input-bordered input-sm w-full rounded-none truncate"
            />
          </div>
          <div className="flex gap-2 justify-end">
            <Link to={'..'} replace className="btn btn-xs px-1 btn-ghost border-[#B1B1B1]">
              <XMarkIcon className="h-5 w-5 text-[#B1B1B1]" />
            </Link>
            <button type="submit" className={`btn btn-xs px-1 text-white bg-purple-75 border-purple-75`}>
              <CheckIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TitleEditForm;
