import PropTypes from 'prop-types';
import { useConversionsByMeasures } from '@/stores/nodeStore';
import { convert, convertFlowRate, getConversionOptions, getMeasures } from '@/utils/unitUtils';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Select from '../core/form/Select';
import usePrevious from '@/hooks/usePrevious';
import ErrorMessage from '../parameters/fields/ErrorMessage';
import { validatorRules } from '@/utils/validators';
import { FLOW_RATE_TYPES } from '@/consts';
import CostOverrideSummary from './CostOverrideSummary';
import NumberInputField from '../parameters/fields/NumberInputField';
import { isEmpty } from '@/utils/miscUtils';

const AnchorForm = ({
  defaultValues,
  onSubmit,
  onCancel,
  onToggleForm,
  ioName,
  ioType,
  costOverride,
  showOverrideForm,
}) => {
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = methods;
  const [conversionStr, measure, flowRate] = useWatch({ control, name: ['conversion', 'measure', 'flowRate'] });
  const prevConversionStr = usePrevious(conversionStr);
  const prevMeasure = usePrevious(measure);
  const prevFlowRate = usePrevious(flowRate);

  const conversionsByMeasures = useConversionsByMeasures(ioType, ioName);
  const conversions = conversionsByMeasures[measure];
  const options = getConversionOptions(conversions);
  const measures = getMeasures(conversionsByMeasures);

  const checkKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  const cancel = () => {
    reset();
    onCancel();
  };

  const submit = data => {
    onSubmit(data);
    reset();
  };

  // convert value based on the given conversion
  useEffect(() => {
    if (conversionStr && prevConversionStr) {
      const formValues = getValues();
      const from = JSON.parse(prevConversionStr);
      const to = JSON.parse(conversionStr);
      const value = parseFloat(formValues.value);
      const convertedByUnit = convert(from, to, value);
      const convertByFlowRate = convertFlowRate(prevFlowRate ?? 1, flowRate ?? 1, convertedByUnit);

      if (!isNaN(convertByFlowRate)) {
        setValue('value', convertByFlowRate);
      }
    }
  }, [conversionStr, prevConversionStr, flowRate, prevFlowRate, setValue, getValues]);

  useEffect(() => {
    if (measure && prevMeasure && measure !== prevMeasure) {
      const conversion = conversions[0];
      setValue('conversion', JSON.stringify(conversion));
    }
  }, [measure, prevMeasure, conversions, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} onKeyDown={checkKeyDown} className="mt-7">
        <div className="mb-6 flex justify-between">
          {measures.map(measureTypes => (
            <input
              {...register('measure')}
              key={measureTypes.value}
              className="btn btn-sm"
              type="radio"
              aria-label={measureTypes.label}
              value={measureTypes.value}
            />
          ))}
        </div>
        <div className="join w-full">
          <div className="flex-1">
            <NumberInputField
              rules={{ required: true, valueAsNumber: true, validate: validatorRules.numeric() }}
              type="text"
              name="value"
              placeholder="Enter basis value"
              className="input input-sm join-item input-bordered w-full"
            />
            {errors.value?.message && <ErrorMessage text={errors.value?.message.toString()} />}
          </div>
          <Select name="conversion" options={options} className="join-item select-sm border-l-0" />
          {ioName !== 'electricity' && (
            <Select
              name="flowRate"
              className="select-sm ml-2"
              registerOptions={{ valueAsNumber: true }}
              options={FLOW_RATE_TYPES}
            />
          )}
        </div>
        <div className="modal-action justify-between items-center gap-6">
          {showOverrideForm && <CostOverrideSummary costOverride={costOverride} onToggleForm={onToggleForm} />}
          <div className="flex gap-2 flex-1 justify-end">
            <button onClick={cancel} type="button" className="btn btn-secondary btn-sm">
              Cancel
            </button>
            <button type="submit" className={`btn btn-primary btn-sm ${isEmpty(dirtyFields) ? 'btn-disabled' : ''}`}>
              Save
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

AnchorForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  ioName: PropTypes.string,
  ioType: PropTypes.string,
  onToggleForm: PropTypes.func,
  costOverride: PropTypes.object,
  showOverrideForm: PropTypes.bool,
};

export default AnchorForm;
