import { exportComparisonsData } from '@/api/comparisons';
import { useComparison } from '@/stores/comparisonStore';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import kebabCase from 'just-kebab-case';

const ExportDataButton = () => {
  const { id, name } = useComparison();

  const download = async event => {
    event.preventDefault();
    const { data } = await exportComparisonsData(id);
    const objectUrl = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = objectUrl;
    link.download = `${kebabCase(name)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={download} className="right-5 top-5 btn btn-sm btn-secondary mr-4">
      <ArrowDownTrayIcon className="w-5 h-5" />
      Export Data
    </button>
  );
};

export default ExportDataButton;
