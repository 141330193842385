import PropTypes from 'prop-types';

import { useState } from 'react';
import AnalysisBox from '../core/AnalysisBox';

const ParameterTabsView = ({ categories, conditionalChecks, renderParamsBySubCategory }) => {
  const [activeTab, setActiveTab] = useState(Object.keys(categories)[0] || '');

  const renderParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params;

    if (!paramsByCategory) {
      return null;
    }
    return <div className="grid grid-cols-3 mt-6 gap-6">{renderParamsBySubCategory(paramsByCategory)}</div>;
  };

  return (
    <AnalysisBox>
      <ul className="flex gap-x-2 border-b border-gray-100">
        {Object.values(categories).map(category => {
          const { categoryName, params } = category;
          const showCategory = params.some(({ name: paramName }) => conditionalChecks[paramName].fieldCondMet);

          return (
            showCategory && (
              <li key={categoryName} className="inline-block relative">
                <button
                  type="button"
                  onClick={() => {
                    setActiveTab(categoryName);
                  }}
                  className={`text-sm flex items-center pl-3 pr-3 px-1 pt-2 pb-3 rounded hover:bg-gray-100 ${activeTab === categoryName ? 'after:content-[""] after:h-[3px] after:bg-blue-400 after:w-full text-gray-800 after:absolute after:bottom-0 after:left-0 after:rounded-t-sm' : 'text-gray-800'}`}
                >
                  {categoryName || 'Generators'}
                </button>
              </li>
            )
          );
        })}
      </ul>
      <div className="overflow-y-auto flex-grow-[2]">
        {Object.values(categories).map(({ categoryName }) => (
          <div className={`${activeTab === categoryName ? 'visible' : 'hidden'}`} key={categoryName}>
            {renderParamsByCategory(categoryName)}
          </div>
        ))}
      </div>
    </AnalysisBox>
  );
};

ParameterTabsView.propTypes = {
  categories: PropTypes.object,
  renderParamsBySubCategory: PropTypes.func,
  conditionalChecks: PropTypes.object,
};

export default ParameterTabsView;
