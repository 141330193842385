import { unitToString } from '@/utils/unitUtils';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { CHART_COLORS, HOURS_PER_WEEK, HOURS_PER_YEAR, startingHours } from '@/consts';
import { getDateFromHours, getStartingHourForMonth } from '@/utils/miscUtils';

const calculateDate = (index, pageIndex, flowRate) => {
  const value = flowRate === HOURS_PER_WEEK ? pageIndex * flowRate : getStartingHourForMonth(pageIndex);

  return getDateFromHours(index + 1 + value);
};

const buildOptions = (series, pageIndex, flowRate, label = '') => {
  const options = {
    responsive: true,
    animation: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 20,
        top: 20,
        left: 20,
        bottom: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxTicksLimit: 24,
          minRotation: 45,
          maxRotation: 45,
          callback: (val, index) => {
            if (index == 0) return calculateDate(0, pageIndex, flowRate);

            if (flowRate === HOURS_PER_YEAR) {
              return startingHours.indexOf(val) >= 0 ? calculateDate(index, pageIndex, flowRate) : '';
            }

            return (index + 1) % 24 === 0 ? calculateDate(index, pageIndex, flowRate) : '';
          },
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          display: true,
          lineWidth: 2,
        },
      },
      y: {
        beginAtZero: true,
        stacked: true,
        title: {
          display: true,
          text: unitToString(series[0].unit),
        },
        border: {
          dash: [4, 4],
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: label,
      },
    },
  };

  return options;
};

const buildData = (series, flowRate) => {
  const labels = [];
  const datasets = series.map((s, index) => ({
    label: s.label,
    data: s.data.map(d => d.value),
    backgroundColor: CHART_COLORS[index],
    borderColor: CHART_COLORS[index],
    borderWidth: 0,
    barPercentage: flowRate === HOURS_PER_WEEK ? 0.93 : 1,
    categoryPercentage: 1,
  }));

  series[0].data.forEach((data, index) => labels.push(index + 1));

  return {
    labels,
    datasets,
  };
};

const TimeSeriesBarChart = ({ series, label, flowRate, pageIndex }) => {
  const data = buildData(series, flowRate);
  const options = buildOptions(series, pageIndex, flowRate, label);

  return (
    <div className="min-h-80 relative mr-2">
      <Bar data={data} options={options} />
    </div>
  );
};

TimeSeriesBarChart.propTypes = {
  label: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  flowRate: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default TimeSeriesBarChart;
