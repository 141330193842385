import { comparisonActions, useLCAComparisonPlot } from '@/stores/comparisonStore';
import LCAComparisonForm from './LCAComparisonForm';
import StackedBarChart from '@/components/charts/StackedBarChart';
import { getLCAYaxisLabel } from '@/utils/comparisonUtils';
import { isEmpty } from '@/utils/miscUtils';
import LCAComparisonSummary from './LCAComparisonSummary';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const LCAComparisonPlot = () => {
  const plot = useLCAComparisonPlot();
  const updatePlot = formData => comparisonActions.updatePlot('lca', formData);

  if (isEmpty(plot)) {
    return null;
  }

  const { results } = plot;
  const yAxisLabel = getLCAYaxisLabel(plot);

  return (
    <AnalysisContainer title="Emissions Analysis">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <AnalysisBox>
            <>
              {results && (
                <StackedBarChart
                  rows={results.rows}
                  columns={results.columns}
                  yAxisLabel={yAxisLabel}
                  labelsInCSV={true}
                />
              )}
              <LCAComparisonForm onSubmit={updatePlot} defaultValues={plot} />
            </>
          </AnalysisBox>
        </div>
        <AnalysisBox>
          {results && (
            <LCAComparisonSummary
              minTotal={results.minTotal}
              maxTotal={results.maxTotal}
              maxProportions={results.maxProportions}
              unit={yAxisLabel}
            />
          )}
        </AnalysisBox>
      </div>
    </AnalysisContainer>
  );
};

export default LCAComparisonPlot;
