import { useOperationsHouryTimeseriesOptions } from '@/stores/systemAnalysisStore';
import TimeSeriesResult from './TimeSeriesResult';
import { BAR_CHART, LINE_CHART } from '@/consts';

const OperationsTimeSeriesResult = () => {
  const options = useOperationsHouryTimeseriesOptions();

  if (!options.length) {
    return null;
  }

  const isHydrogenProduction = options[0]?.systemType === 'hydrogen_production';
  const chartType = isHydrogenProduction ? BAR_CHART : LINE_CHART;

  return <TimeSeriesResult options={options} chartType={chartType} />;
};

export default OperationsTimeSeriesResult;
