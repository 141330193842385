import { SYSTEM_STATUS } from '@/consts';
import { useSystemStatus, systemActions } from '@/stores/systemStore';
import { useFormContext } from 'react-hook-form';
import { PlayIcon } from '@heroicons/react/24/outline';

const RunSystemButton = () => {
  const status = useSystemStatus();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext();

  const saveAndRun = async data => {
    if (isDirty) {
      await systemActions.patchParams(data);
      reset(data);
    }
    systemActions.run();
  };

  if (status === SYSTEM_STATUS.running) {
    return (
      <button className="btn btn-sm btn-secondary">
        <span className="loading loading-spinner"></span>
      </button>
    );
  }

  return (
    <a onClick={handleSubmit(saveAndRun)} className="btn btn-sm btn-primary">
      <PlayIcon className="w-5 h-5" />
      Run
    </a>
  );
};

export default RunSystemButton;
