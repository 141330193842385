import PropTypes from 'prop-types';
import useCheckConditionals from './useCheckConditionals';

import InputField from './fields/InputField';
import SelectField from './fields/SelectField';
import ToggleField from './fields/ToggleField';
import BlockField from './fields/BlockField';
import { parseCategories } from '@/utils/paramsUtils';
import ParameterTabsView from './ParameterTabsView';
import ParameterGridView from './ParameterGridView';
import { VIEW_TYPES } from '@/consts';
import LocationField from './fields/LocationField';

const paramTypes = {
  continuous: InputField, // deprecated
  numeric: InputField,
  string: InputField,
  options: SelectField,
  boolean: ToggleField,
  block: BlockField,
  location: LocationField,
  // TODO: register other types
};

const ParameterFields = ({
  params,
  categories,
  className = '',
  parentName,
  field,
  index = '',
  viewType = VIEW_TYPES.grid,
}) => {
  const prefix = parentName ? `${parentName}.${index}.` : '';
  const conditionalChecks = useCheckConditionals(params, prefix);

  const renderParamsBySubCategory = params => {
    const subCategories = parseCategories(params, 1);

    return Object.values(subCategories).map(({ categoryName, params }, index) => {
      const showCategory = params.some(
        ({ name: paramName }) => !conditionalChecks[paramName] || conditionalChecks[paramName].fieldCondMet,
      );

      if (!showCategory) {
        return renderParams(params);
      }

      return (
        <div key={`subcategory-${index}`}>
          {categoryName ? (
            <>
              <p className="border-b font-medium text-xs pb-2 ml-4 mr-4 mb-4">{categoryName}</p>
              {renderParams(params)}
            </>
          ) : (
            renderParams(params)
          )}
        </div>
      );
    });
  };

  const renderParams = params => {
    return params
      .filter(param => paramTypes[param.type])
      .map(param => {
        const name = `${prefix}${param.name}`;
        const FieldComponent = paramTypes[param.type];
        const { fieldCondMet, watchedFields, defaultValue: value } = conditionalChecks[name];
        const defaultValue = value ?? field?.[param.name];
        const tabsClasses = viewType === VIEW_TYPES.tabs ? 'group node' : '';
        const classes = param.type !== 'block' ? `mx-auto pb-2 mt-2 mb-2 ${className} ${tabsClasses}` : '';

        return (
          fieldCondMet && (
            <div key={`${prefix}${name}`} className={classes}>
              <FieldComponent
                key={name}
                name={name}
                param={param}
                prefix={prefix}
                defaultValue={defaultValue}
                watchedFields={watchedFields}
              />
            </div>
          )
        );
      });
  };

  if (!categories) {
    return renderParamsBySubCategory(params);
  }

  return (
    <>
      {viewType === VIEW_TYPES.grid && (
        <ParameterGridView
          categories={categories}
          renderParamsBySubCategory={renderParamsBySubCategory}
          conditionalChecks={conditionalChecks}
        />
      )}
      {viewType === VIEW_TYPES.tabs && (
        <ParameterTabsView
          categories={categories}
          renderParamsBySubCategory={renderParamsBySubCategory}
          conditionalChecks={conditionalChecks}
        />
      )}
    </>
  );
};

ParameterFields.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.object,
  className: PropTypes.string,
  parentName: PropTypes.string,
  index: PropTypes.number,
  field: PropTypes.object,
  viewType: PropTypes.string,
};

export default ParameterFields;
