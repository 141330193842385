import ComparisonsList from '@/components/comparisons/ComparisonsList';
import { Outlet, useRouteLoaderData } from 'react-router-dom';
import ListPane from '@/components/core/ListPane';
import MenuPane from '@/components/core/MenuPane';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useLocation } from 'react-router-dom';
import ComparisonEmptyState from '@/components/comparisons/ComparisonEmptyState';

const ComparisonsRoute = () => {
  const {
    comparisons: { results: comparisons },
  } = useRouteLoaderData('project');
  const { pathname } = useLocation();
  const displayEmptyState = pathname.match(/comparisons$/);

  return (
    <PanelGroup direction="horizontal">
      <MenuPane minSize={220}>
        <ListPane>
          <ComparisonsList comparisons={comparisons} />
        </ListPane>
      </MenuPane>
      <Panel className="w-full !overflow-y-scroll bg-canvas">
        {displayEmptyState ? <ComparisonEmptyState /> : <Outlet />}
      </Panel>
    </PanelGroup>
  );
};

export default ComparisonsRoute;
