const filterNodeTypes = (term, activeCategory, product, nodeTypesByCategory) => {
  let activeNodeTypes = nodeTypesByCategory[activeCategory] || [];
  if (term) {
    const regex = new RegExp(term, 'i');
    activeNodeTypes = activeNodeTypes.filter(nodeType => regex.test(nodeType.searchIndex));
  }

  if (!product || product === 'Select Product') {
    return activeNodeTypes;
  }

  return activeNodeTypes.filter(
    node => node.output_types.some(o => o.label === product) || node.input_types.some(i => i.label === product),
  );
};

export default filterNodeTypes;
