import { getSystem, getSystemResult, getSystemResults } from '@/api/systems';
import { parseCategories } from '@/utils/paramsUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { systemActions } from './systemStore';

const getCapacityValue = (capacity, name) => capacity.attributes.find(a => a.name === name)?.value.scalar || 0;

const initialValues = {
  results: {},
  systemId: null,
};

const useSystemAnalysisStore = create()(immer(() => initialValues));

const { setState: set, getState: get } = useSystemAnalysisStore;

const parsers = {
  costs: result => {
    const { costs } = result;
    return { costs };
  },
  hourly_timeseries: result => {
    const { series } = result;
    return { series };
  },
  dataframe: result => {
    return result;
  },
  attributes: result => {
    const { attributes } = result;
    const categories = parseCategories(attributes);
    return { attributes, categories };
  },
};

// actions
export const systemAnalysisActions = {
  init: async systemId => {
    systemAnalysisActions.clear();
    const { data: system } = await getSystem(systemId);
    const { id, results: systemResults } = system;
    const resultNames = systemResults.map(result => ({ result_name: result.name }));
    const { data } = await getSystemResults(id, resultNames);
    const results = {};

    data.forEach((result, index) => {
      const resultMeta = systemResults[index];
      const parsedData = parsers[resultMeta.result_type](result);
      results[resultMeta.name] = { ...resultMeta, ...result, ...parsedData, systemType: system.system_type };
    });

    set({ results, systemId });

    systemActions.init(system);

    return system;
  },

  clear: () => set(initialValues, true),

  updateResult: async data => {
    const { systemId, results } = get();
    const { data: result } = await getSystemResult(systemId, {
      result_name: data.plot,
      unit: JSON.parse(data.unit),
    });

    const currentResult = results[data.plot];
    const resultType = currentResult.result_type;
    const parsedData = parsers[resultType](result);

    set(state => {
      state.results[data.plot] = { ...currentResult, ...result, ...parsedData };
    });
  },
};

// selectors
export const useSystemResult = name =>
  useSystemAnalysisStore(store => {
    return store.results[name];
  });
export const useTechnologies = () =>
  useSystemAnalysisStore(store =>
    Object.values(store.results).filter(result => result.name.match(/summary_|_attributes/)),
  );
export const useCapacities = () =>
  useSystemAnalysisStore(store => {
    const capacities = Object.values(store.results)
      .filter(result => result.result_type === 'attributes' && result.name.match(/_attributes/))
      .map(result => ({
        label: result.label,
        attributes: result.attributes.filter(a => a.name === 'existing_capacity' || a.name === 'new_capacity'),
      }));

    const existingCapacityLabel = capacities[0]?.attributes.find(a => a.name === 'existing_capacity').label;
    const newCapacityLabel = capacities[0]?.attributes.find(a => a.name === 'new_capacity').label;
    const unit = capacities[0]?.attributes[0]?.value.unit;

    const columns = capacities.map(c => c.label);

    const rows = [
      [existingCapacityLabel, ...capacities.map(c => getCapacityValue(c, 'existing_capacity'))],
      [newCapacityLabel, ...capacities.map(c => getCapacityValue(c, 'new_capacity'))],
    ];

    return { rows, columns, unit };
  });
export const useHouryTimeseriesOptions = () =>
  useSystemAnalysisStore(store => {
    return Object.values(store.results)
      .filter(result => result.result_type === 'hourly_timeseries' && !result.name.match(/operations_/))
      .map(result => ({
        label: result.label,
        value: result.name,
        systemType: result.systemType,
        unit: result.unit,
        convertibleUnits: result.convertible_units,
      }));
  });
export const useOperationsHouryTimeseriesOptions = () =>
  useSystemAnalysisStore(store => {
    return Object.values(store.results)
      .filter(result => result.result_type === 'hourly_timeseries' && result.name.match(/operations_/))
      .map(result => ({
        label: result.label,
        value: result.name,
        systemType: result.systemType,
        unit: result.unit,
        convertibleUnits: result.convertible_units,
      }));
  });

export const useCosts = () => useSystemAnalysisStore(store => store.results.costs);

export const useDataframes = () =>
  useSystemAnalysisStore(store => Object.values(store.results).filter(result => result.result_type === 'dataframe'));

export const useResults = () => useSystemAnalysisStore(store => store.results);

export default useSystemAnalysisStore;
