import PropTypes from 'prop-types';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { ChartBarIcon, MapIcon } from '@heroicons/react/24/outline';
import { ANALYSIS_STATUS } from '@/consts';

const PathwayAnalysesList = ({ pathways }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  if (!pathways?.length) {
    return null;
  }

  const gotoPathway = (event, pathway) => {
    event.preventDefault();
    navigate(`/projects/${projectId}/build/pathways/${pathway.id}`);
  };

  return (
    <>
      <button className="hover:bg-gray-50 flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700">
        <ChartBarIcon className="w-4 h-4 shrink-0" />
        <span className="truncate">Pathways</span>
      </button>
      <ul className="px-1">
        {pathways
          .filter(pathway => pathway.analysis_status === ANALYSIS_STATUS.succeeded && !pathway.analysis_stale)
          .map(pathway => (
            <li key={pathway.id} className="group pl-2">
              <NavLink
                className={({ isActive }) =>
                  `flex items-center justify-between rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}`
                }
                state={{ pathwayUrl: `/projects/${projectId}/build/pathways/${pathway.id}` }}
                to={`/projects/${projectId}/analysis/pathways/${pathway.id}`}
              >
                <span className="truncate">{pathway.name}</span>
                <div className="group-hover:flex absolute right-5 hidden bg-canvas">
                  <MapIcon onClick={event => gotoPathway(event, pathway)} className="h-5 w-5" />
                </div>
              </NavLink>
            </li>
          ))}
      </ul>
    </>
  );
};

PathwayAnalysesList.propTypes = {
  pathways: PropTypes.arrayOf(PropTypes.object),
};

export default PathwayAnalysesList;
