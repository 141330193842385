import { Link, useLoaderData, useSubmit, useNavigate } from 'react-router-dom';
import ListActions from '@/components/core/ListActions';
import { confirm } from '@/components/core/createConfirmation';

const ProjectList = () => {
  const {
    data: { results: projects },
  } = useLoaderData();
  const submit = useSubmit();
  const navigate = useNavigate();

  const remove = async project => {
    if (await confirm('Are you sure you want to remove this project?')) {
      submit(
        {},
        {
          method: 'delete',
          action: `/projects/${project.id}/destroy`,
        },
      );
    }
  };

  const edit = project => navigate(`/projects/${project.id}/edit`);

  if (!projects.length) {
    return <p className="p-5 text-center">No projects found</p>;
  }

  return (
    <>
      <table className="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400 rounded">
        <tbody>
          {projects.map(project => (
            <tr
              key={project.id}
              className="border-b dark:bg-gray-200 dark:border-gray-200 rounded hover:bg-blue-200 hover:text-gray-800"
            >
              <td className="px-6 py-4">
                <Link to={`/projects/${project.id}`}>{project.name}</Link>
              </td>
              <td className="text-right">
                <ListActions item={project} onRemove={remove} onEdit={edit} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProjectList;
