import PropTypes from 'prop-types';
import AnalysisContainer from '../core/AnalysisContainer';

const ParameterGridView = ({ categories, renderParamsBySubCategory, conditionalChecks }) => {
  const renderParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params;
    const isGenerator = paramsByCategory && paramsByCategory.some(({ name }) => name === 'generators');

    if (!paramsByCategory) {
      return null;
    }
    return (
      <AnalysisContainer key={categoryName} title={categoryName + (isGenerator ? 'Generator Parameters' : '')}>
        <div className="p-4 rounded-xl shadow-sm bg-white">{renderParamsBySubCategory(paramsByCategory)}</div>
      </AnalysisContainer>
    );
  };

  return (
    <div className="grid grid-cols-2 gap-4 bg-canvas px-4 pb-4">
      {Object.values(categories).map(({ categoryName, params }) => {
        const showCategory =
          categoryName && params.some(({ name: paramName }) => conditionalChecks[paramName].fieldCondMet);
        return showCategory && renderParamsByCategory(categoryName);
      })}
      {renderParamsByCategory('')}
    </div>
  );
};

ParameterGridView.propTypes = {
  categories: PropTypes.object,
  renderParamsBySubCategory: PropTypes.func,
  conditionalChecks: PropTypes.object,
};

export default ParameterGridView;
