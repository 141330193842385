import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';

const initialValues = {
  walkthroughs: {
    comparison: true,
  },
  theme: 'sesame-small',
  appVersion: null,
};

const useSettingsStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'settingsStore',
      version: 1,
    }),
  ),
);

const { setState: set } = useSettingsStore;

// actions
export const settingsActions = {
  disableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = false;
    }),

  enableWalkthrough: name =>
    set(state => {
      state.walkthroughs[name] = true;
    }),

  updateTheme: theme => {
    set({ theme });
  },

  fetchVersion: async () => {
    const { data } = await userApi.getVersion();
    set({ appVersion: data.version });
  },
};

// selectors
export const useTourSettings = () => useSettingsStore(store => store.tours);
export const useTheme = () => useSettingsStore(store => store.theme);
export const useAppVersion = () => useSettingsStore(store => store.appVersion);

export const useIsWalkthroughEnabled = type =>
  useSettingsStore(store => {
    return store?.walkthroughs?.[type] === undefined || store?.walkthroughs?.[type] === true;
  });
export default useSettingsStore;
