import ComparisonsRoute from './ComparisonsRoute';
import ComparisonRoute from './ComparisonRoute';
import { createComparison, deleteComparison, getComparison, patchComparison } from '@/api/comparisons';
import EditComparisonsModal from '@/components/comparisons/EditComparisonsModal';
import { defer, redirect } from 'react-router-dom';
import { comparisonActions } from '@/stores/comparisonStore';

const comparisonsRouter = {
  path: '/projects/:projectId/comparisons',
  id: 'comparisons',
  element: <ComparisonsRoute />,
  loader: () => {
    comparisonActions.clearPathways();
    return {};
  },
  children: [
    {
      path: '/projects/:projectId/comparisons/new',
      element: <EditComparisonsModal title="New Comparison" />,
      action: async ({ request, params }) => {
        const { projectId } = params;
        const form = await request.formData();
        const { name } = Object.fromEntries(form);

        try {
          const { data } = await createComparison(projectId, { name });
          return redirect(`/projects/${projectId}/comparisons/${data.id}`);
        } catch (error) {
          return redirect(`/projects/${projectId}/comparisons/new`);
        }
      },
    },
    {
      path: '/projects/:projectId/comparisons/:comparisonId',
      id: 'comparison',
      element: <ComparisonRoute />,
      errorElement: <ComparisonRoute />,
      handle: {
        crumb: comparison => ['Compare', comparison?.name],
      },
      loader: async ({ params: { projectId, comparisonId } }) => {
        const promise = comparisonActions.init(projectId, comparisonId);
        return defer({ promise });
      },
      children: [
        {
          path: '/projects/:projectId/comparisons/:comparisonId/edit',
          element: <EditComparisonsModal title="Edit Comparison" />,
          loader: async ({ params: { comparisonId } }) => {
            const { data: comparison } = await getComparison(comparisonId);
            return comparison;
          },
          action: async ({ request, params }) => {
            const { projectId, comparisonId } = params;
            const form = await request.formData();
            const { name } = Object.fromEntries(form);

            await patchComparison(comparisonId, { name });

            return redirect(`/projects/${projectId}/comparisons/${comparisonId}`);
          },
        },
        {
          path: '/projects/:projectId/comparisons/:comparisonId/destroy',
          action: async ({ params }) => {
            const { projectId, comparisonId } = params;
            await deleteComparison(comparisonId);
            return redirect(`/projects/${projectId}/comparisons`);
          },
        },
      ],
    },
  ],
};

export default comparisonsRouter;
