import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import useSetDefaultValue from '../useSetDefaultValue';
import ConversionField from '../ConversionField';
import useConversion from '../useConversion';

const GroupInputField = ({ param, name, defaultValue, parentName, watchedFields, onChange, parentValues }) => {
  const { validators, label, conversion, conversions } = param;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message;

  useSetDefaultValue(name, defaultValue, watchedFields);

  const { validators: dynamicValidators } = useConversion(param, name, `${parentName}.conversion.${param.name}`);
  const validate = { ...validators, ...dynamicValidators };
  const registration = register(name, {
    validate,
    valueAsNumber: true,
  });

  const handleChange = event => {
    registration.onChange(event);
    onChange({ ...parentValues, [param.name]: Number(event.target.value) });
  };

  return (
    <tr>
      <td className="text-sm p-2 pl-4">{label}</td>
      <td className="p-2">
        <input
          id={name}
          {...registration}
          type="text"
          onChange={handleChange}
          name={name}
          defaultValue={defaultValue}
          className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full ${errorMessage ? 'input-error' : ''}`}
        />
        <ErrorMessage text={errorMessage} />
      </td>
      <td className="p-2 pr-4 w-full">
        {conversion && (
          <ConversionField
            className="w-full"
            name={`${parentName}.conversion.${param.name}`}
            conversions={conversions}
          />
        )}
      </td>
    </tr>
  );
};

GroupInputField.propTypes = {
  param: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  parentName: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  watchedFields: PropTypes.object,
  parentValues: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default GroupInputField;
