import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import { PlusIcon, ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import ComparisonActionDropdown from './ComparisonActionDropdown';
import { useEffect } from 'react';
import { comparisonActions } from '@/stores/comparisonStore';

const ComparisonsList = ({ comparisons = [] }) => {
  const { projectId } = useParams();

  const navigate = useNavigate();

  const addNew = event => {
    event.stopPropagation();
    navigate(`/projects/${projectId}/comparisons/new`);
  };

  useEffect(() => () => comparisonActions.unmount(), []);

  return (
    <>
      <button
        onClick={addNew}
        className="hover:bg-gray-50 p-2 flex items-center w-full text-left rounded-md gap-x-3 text-sm leading-6 font-semibold text-gray-700"
      >
        <ArrowsRightLeftIcon className="w-4 h-4 shrink-0" />
        <span className="truncate">Comparison Cases</span>
        <PlusIcon className="ml-auto h-4 w-4 shrink-0 text-gray-500" />
      </button>
      <ul className="px-1">
        {comparisons.map(comparison => (
          <li key={comparison.id} className="group pl-2">
            <NavLink
              className={({ isActive }) =>
                `flex items-center justify-between rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}`
              }
              to={`/projects/${projectId}/comparisons/${comparison.id}`}
            >
              <span className="truncate">{comparison.name}</span>
              <div className="group-hover:flex hidden">
                <ComparisonActionDropdown comparison={comparison} />
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};

ComparisonsList.propTypes = {
  comparisons: PropTypes.arrayOf(PropTypes.object),
};

export default ComparisonsList;
