import axios from 'axios';

const { post, get, put } = axios;

export default {
  login: data => post('/v1/token/', data, { withCredentials: true }),
  logout: () => post('/v1/logout/'),
  updatePassword: data => put('/v1/user/password/', data),
  refresh: refreshToken => post('/v1/token/refresh/', { refresh: refreshToken }),
  getUserInfo: () => get('/v1/user/'),
  getVersion: () => get(`/`),
};
