import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import PanelHeader from '../core/PanelHeader';
import { isEmpty } from '@/utils/miscUtils';
import NodeFields from './NodeFields';

const NodeForm = ({ params, categories, actions, title, description, onSubmit, className = '' }) => {
  const methods = useFormContext();
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = methods;

  const onInnerSubmit = async data => {
    await onSubmit(data);
    reset(data);
  };

  const checkKeyDown = e => {
    if (e.key === 'Enter') {
      if (document.activeElement.tagName !== 'body') {
        document.activeElement.blur();
      }

      setTimeout(() => {
        handleSubmit(onInnerSubmit)(e);
      }, 100);
    }
  };

  return (
    <form onKeyDown={checkKeyDown} onSubmit={handleSubmit(onInnerSubmit)} noValidate className="h-full">
      <PanelHeader title={title} isEditable>
        {actions}
        {isSubmitting ? (
          <button className="btn btn-sm mr-2 btn-secondary">
            <span className="loading loading-spinner"></span>
          </button>
        ) : (
          <button type="submit" className={`btn mr-2 btn-primary btn-sm ${isEmpty(dirtyFields) ? 'btn-disabled' : ''}`}>
            Save
          </button>
        )}
      </PanelHeader>
      <NodeFields params={params} description={description} categories={categories} className={className} />
    </form>
  );
};

NodeForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  description: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  className: PropTypes.string,
  categories: PropTypes.object,
};

export default NodeForm;
