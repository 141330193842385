import PropTypes from 'prop-types';
import { useParams, useNavigate, useSubmit } from 'react-router-dom';
import ActionsDropdown from '../core/ActionsDropdown';
import { confirm } from '@/components/core/createConfirmation';

const PathwayActionsDropdown = ({ pathway }) => {
  const { pathwayId } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const actionPath = pathwayId ? `pathways/${pathway.id}` : `pathways/${pathway.id}`;
  const remove = async () => {
    if (await confirm('Are you sure you want to remove this pathway?')) {
      submit(pathway, {
        method: 'delete',
        action: `${actionPath}/destroy`,
      });
    }
  };

  const edit = pathway => {
    navigate(`${actionPath}/edit`, { state: { pathway } });
  };

  const duplicate = pathway => {
    navigate(`${actionPath}/duplicate`, { state: { pathway } });
  };

  return (
    <>
      <ActionsDropdown onDelete={remove} onDuplicate={duplicate} onRename={edit} resource={pathway} />
    </>
  );
};

PathwayActionsDropdown.propTypes = {
  pathway: PropTypes.object,
  pathwayRef: PropTypes.object,
};

export default PathwayActionsDropdown;
