import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SvgViewer from './SvgViewer';
import sesameLogo from '@/assets/sesame.svg';
import FeedbackButton from './FeedbackButton';
import AccountActionsDropdown from './AccountActionsDropdown';

const Header = ({ children }) => {
  return (
    <header className="bg-ivory-500 border-b pt-2 pb-2 pl-4 pr-4 border-gray-200">
      <nav className="flex items-center justify-between">
        <div className="flex lg:flex-1">
          <Link to="/" className="p-2 flex items-center justify-end space-x-1">
            {
              <div className="w-32">
                <SvgViewer x={0} y={0} width={802} height={202} src={sesameLogo} />
              </div>
            }
          </Link>
        </div>
        <div className="flex lg:gap-x-12">{children}</div>
        <div className="lg:flex lg:justify-end flex-col">
          <AccountActionsDropdown />
          <div className="text-right">
            <FeedbackButton />
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
