import axios from 'axios';

const { get, post, patch } = axios;

export const getSystem = systemId => get(`/v1/systems/${systemId}/`);
export const getSystems = (projectId, params = {}) => get(`/v1/projects/${projectId}/systems/`, { params });
export const createSystem = (projectId, data) => post(`/v1/projects/${projectId}/systems/`, data);
export const patchSystem = (systemId, data) => patch(`/v1/systems/${systemId}/`, data);
export const deleteSystem = systemId => axios.delete(`/v1/systems/${systemId}/`);
export const runSystem = (systemId, data) => post(`/v1/systems/${systemId}/run/`, data);
export const duplicateSystem = (systemId, params) => post(`/v1/systems/${systemId}/duplicate/`, params ?? {});
export const getSystemResult = (systemId, params) => post(`/v1/systems/${systemId}/result/`, params ?? {});
export const getSystemResults = (systemId, params) => post(`/v1/systems/${systemId}/results/`, params ?? {});
