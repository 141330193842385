import ListPane from '@/components/core/ListPane';
import MenuPane from '@/components/core/MenuPane';
import PathwayList from '@/components/pathways/PathwayList';
import SystemList from '@/components/systems/SystemList';
import { useEffect } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { menuAction } from '@/stores/menuStore';
import { Panel, PanelGroup } from 'react-resizable-panels';
import { useLocation } from 'react-router-dom';
import EmptyState from '@/components/pathways/EmptyState';

const BuildRoute = () => {
  useEffect(() => {
    menuAction.open();
  }, []);

  const { projectId } = useParams();
  const { pathname } = useLocation();
  const displayEmptyState = pathname.match(/build$/);

  return (
    <PanelGroup direction="horizontal">
      <MenuPane>
        <ListPane>
          <div className="mb-2">
            <PathwayList />
          </div>
          <div className="mb-2">
            <SystemList />
          </div>
        </ListPane>
      </MenuPane>
      <Panel className="w-full flex flex-col">
        {displayEmptyState ? (
          <EmptyState title="Start by building a pathway or system.">
            <>
              <NavLink
                to={`/projects/${projectId}/build/pathways/new`}
                className="btn btn-primary rounded-md leading-6 font-semibold flex"
              >
                Build Pathway
              </NavLink>
              <NavLink
                to={`/projects/${projectId}/build/systems/new`}
                className="btn btn-secondary rounded-md leading-6 font-semibold flex"
              >
                Build System
              </NavLink>
            </>
          </EmptyState>
        ) : (
          <Outlet />
        )}
      </Panel>
    </PanelGroup>
  );
};

export default BuildRoute;
