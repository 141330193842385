import WorkbenchTableForm from './WorkbenchTableForm';
import {
  useIsLoading,
  useIsValid,
  useSweepId,
  useWorkbenchError,
  useWorkbenches,
  workbenchActions,
} from '@/stores/workbenchStore';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const WorkbenchTable = () => {
  const sweepId = useSweepId();
  const workbenches = useWorkbenches();
  const error = useWorkbenchError();
  const isLoading = useIsLoading();
  const isValid = useIsValid();

  const handleRun = () => {
    setTimeout(() => {
      workbenchActions.run();
    });
  };

  return (
    <AnalysisContainer title="Sensitivity Workbench">
      <AnalysisBox>
        <table className="text-left prose prose-th:font-normal prose-th:text-xs min-w-full" width="200px">
          <thead>
            <tr>
              <th className="w-1/6">Module</th>
              <th className="w-1/6">Category</th>
              <th className="w-1/6">Parameter</th>
              <th>Minimum</th>
              <th>Baseline</th>
              <th>Maximum</th>
              <th>Unit</th>
              <th># of steps</th>
            </tr>
          </thead>
          <tbody key={sweepId} className="comparison-case-row">
            {workbenches.map((workbench, index) => (
              <WorkbenchTableForm
                showRemoveButton={workbenches.length === 2}
                showAddButton={index === 0 && workbenches.length < 2}
                key={workbench.key}
                defaultValues={workbench}
                onSubmit={workbenchActions.update}
              />
            ))}
            <tr>
              <td colSpan={7}>{error && <div className="error p-2 text-error text-sm text-center">{error}</div>}</td>
              <td>
                <div className="flex justify-start">
                  <button
                    className={`btn btn-sm btn-primary ${!isValid || isLoading ? 'btn-disabled' : ''}`}
                    onClick={handleRun}
                  >
                    {isLoading ? <span className="loading loading-spinner"></span> : 'Run'}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default WorkbenchTable;
