import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';
import { getNodeOptions } from '@/utils/pathwayUtils';

const CharacteristicsForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const nodes = useNodes();
  const nodeOptions = getNodeOptions(nodes);

  // submit form on every input change
  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit]);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form>
          <div className="mt-2 grid grid-cols-3 gap-2">
            <FormRow className="text-xs mb-2 overflow-hidden w-full" label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

CharacteristicsForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default CharacteristicsForm;
