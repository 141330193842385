import { useLocation, useMatch, useParams, useRouteLoaderData } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SvgViewer from './SvgViewer';
import sesameLogo from '@/assets/sesame.svg';
import { getTabs } from '@/utils/projectUtils';
import Tabs from './Tabs';
import Breadcrumbs from './Breadcrumbs';
import FeedbackButton from './FeedbackButton';

import { menuAction, useCollapsed } from '@/stores/menuStore';
import { Bars3Icon } from '@heroicons/react/24/outline';
import AccountActionsDropdown from './AccountActionsDropdown';

const ProjectHeader = () => {
  const data = useRouteLoaderData('project');
  const location = useLocation();
  const params = useParams();
  const tabs = getTabs(location, data, params);
  const homePath = useMatch('/projects/:projectId/dashboard/*');
  const collapsed = useCollapsed();

  return (
    <>
      <header className="bg-ivory-500 border-b pt-4 pl-4 pr-4 border-gray-200">
        <nav className="flex items-center justify-between mb-4">
          <div className="flex relative">
            <Link to="/" className="px-2">
              <>
                <div className="w-8">
                  <SvgViewer x={0} y={0} width={202} height={202} src={sesameLogo} />
                </div>
              </>
            </Link>
            <div className="flex items-center gap-2">
              <Breadcrumbs />
            </div>
          </div>
          <div className="lg:flex lg:flex-1 lg:justify-end">
            <AccountActionsDropdown />
          </div>
        </nav>
      </header>
      <div className="items-center border-b">
        <div className="mr-10 ml-5 float-start items-center pt-2 mt-1">
          {!homePath && (
            <button
              className="border border-gray-200 rounded shadow-sm bg-white pr-1 pl-1 h-8"
              onClick={collapsed ? menuAction.open : menuAction.close}
            >
              {collapsed ? (
                <Bars3Icon className="cursor-pointer w-6 text-gray-300" />
              ) : (
                <Bars3Icon className="cursor-pointer w-6 text-gray-400 hover:text-gray-300" />
              )}
            </button>
          )}
        </div>
        <div className="justify-between flex ml-24 items-center">
          <Tabs tabs={tabs} />
          <FeedbackButton />
        </div>
      </div>
    </>
  );
};

export default ProjectHeader;
